import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { Link } from "react-router-dom";
import { withLocalize } from "react-localize-redux";
import { Helmet } from "react-helmet";
import styled from "styled-components";

import BannerContent from "../../../components/BannerContent";
import BreadCrumb from "../../../components/BreadCrumb";

import "./style.scss";

const Flex = styled.div`
  display: flex;
`;
const Wrap1200 = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  background: #f5f5f5;
`;

const GuideLine = ({ translate, activeLanguage, ...props }) => {
  let [width, setWidth] = useState(null);
  let [visible, setVisible] = useState(false);
  const { code } = activeLanguage;
  useEffect(() => {
    window.scrollTo({ top: 0 });
    if (window.innerWidth <= 768) {
      setWidth(true);
    } else {
      setWidth(false);
    }
  }, [window.innerWidth]);

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    document.getElementById("backtotopguide").blur();
  };

  const scrollTopkr = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    document.getElementById("backtotopguide_kr").blur();
  };
  const handleKeyPress = event => {
    if (event.key === "Enter") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };
  const handleKeyPressPopup = event => {
    if (event.key === "Enter") {
      setVisible(true);
    }
  };

  return (
    <div className="layout_report_guideline">
      <Helmet>
        <title>{translate("helmet.reportguideline")}</title>
      </Helmet>
      <BannerContent
        title={translate("guideline.breadcrumb2")}
        content={translate("guideline.bannerDescription")}
        background="/Images/bgr_guideline.png"
      />

      <div className="report_guideline">
        <div className="rg_outer_wrap">
          <div className="rg_wrap">
            <BreadCrumb
              firstRouter="/report/guideline"
              secondRouter="/report/guideline"
              firstUrl={translate("guideline.breadcrumb1")}
              secondUrl={translate("guideline.breadcrumb2")}
            />
            <div className="rgw_title">{translate("guideline.reportType")}</div>
            <div className="rgw_content_detail">
              <div className="rgw_process">
                <div className="rgwp_item rgwp_item1">
                  <div
                    className="rgwp_item_img"
                    title={translate(
                      "TooltipReport.Guideline.listtIcon1.icon1"
                    )}
                  >
                    <img
                      style={{ display: "none" }}
                      src=""
                      alt=""
                      title={translate(
                        "TooltipReport.Guideline.listtIcon1.icon1"
                      )}
                    />
                  </div>
                  <span>{translate("guideline.type1")}</span>
                </div>
                <div className="rgwp_item rgwp_item2">
                  <div
                    className="rgwp_item_img"
                    title={translate(
                      "TooltipReport.Guideline.listtIcon1.icon2"
                    )}
                  >
                    <img
                      style={{ display: "none" }}
                      src=""
                      alt=""
                      title={translate(
                        "TooltipReport.Guideline.listtIcon1.icon2"
                      )}
                    />
                  </div>
                  <span className="span_width1">
                    {translate("guideline.type2")}
                  </span>
                </div>
                <div className="rgwp_item rgwp_item3">
                  <div
                    className="rgwp_item_img"
                    title={translate(
                      "TooltipReport.Guideline.listtIcon1.icon3"
                    )}
                  >
                    <img
                      style={{ display: "none" }}
                      src=""
                      alt=""
                      title={translate(
                        "TooltipReport.Guideline.listtIcon1.icon3"
                      )}
                    />
                  </div>
                  <span>{translate("guideline.type3")}</span>
                </div>
                <div className="rgwp_item rgwp_item4">
                  <div
                    className="rgwp_item_img"
                    title={translate(
                      "TooltipReport.Guideline.listtIcon1.icon4"
                    )}
                  >
                    <img
                      style={{ display: "none" }}
                      src=""
                      alt=""
                      title={translate(
                        "TooltipReport.Guideline.listtIcon1.icon4"
                      )}
                    />
                  </div>
                  <span>{translate("guideline.type4")}</span>
                </div>
                <div className="rgwp_item rgwp_item5">
                  <div
                    className="rgwp_item_img"
                    title={translate(
                      "TooltipReport.Guideline.listtIcon1.icon5"
                    )}
                  >
                    <img
                      style={{ display: "none" }}
                      src=""
                      alt=""
                      title={translate(
                        "TooltipReport.Guideline.listtIcon1.icon5"
                      )}
                    />
                  </div>
                  <span>{translate("guideline.type5")}</span>
                </div>
                <div className="rgwp_item rgwp_item6">
                  <div
                    className="rgwp_item_img"
                    title={translate(
                      "TooltipReport.Guideline.listtIcon1.icon6"
                    )}
                  >
                    <img
                      style={{ display: "none" }}
                      src=""
                      alt=""
                      title={translate(
                        "TooltipReport.Guideline.listtIcon1.icon6"
                      )}
                    />
                  </div>
                  <span>{translate("guideline.type6")}</span>
                </div>
                <div className="rgwp_item rgwp_item7">
                  <div
                    className="rgwp_item_img"
                    title={translate(
                      "TooltipReport.Guideline.listtIcon1.icon7"
                    )}
                  >
                    <img
                      style={{ display: "none" }}
                      src=""
                      alt=""
                      title={translate(
                        "TooltipReport.Guideline.listtIcon1.icon7"
                      )}
                    />
                  </div>
                  <span>{translate("guideline.type7")}</span>
                </div>
                <div className="rgwp_item rgwp_item8">
                  <div
                    className="rgwp_item_img"
                    title={translate(
                      "TooltipReport.Guideline.listtIcon1.icon8"
                    )}
                  >
                    <img
                      style={{ display: "none" }}
                      src=""
                      alt=""
                      title={translate(
                        "TooltipReport.Guideline.listtIcon1.icon8"
                      )}
                    />
                  </div>
                  <span>{translate("guideline.type8")}</span>
                </div>
              </div>
              <div className="rgw_lstStep">
                <div className="rgwls_title">
                  {translate("guideline.titleListStep")}
                </div>
                <div className="rgwls_content">
                  <div className="rcwc_list_item">
                    <div className="rcwcl_item">
                      <div className="bgr_item">
                        <img
                          src="/Images/icreportorigin.svg"
                          alt=""
                          title={translate(
                            "webAccessibility.confirmStep1Title"
                          )}
                        />
                      </div>
                      <span>
                        <span>{translate("reportProcess.spanStep1")}</span>
                      </span>
                    </div>
                    <div className="rcwcl_item">
                      <div className="bgr_item">
                        <img
                          src="/Images/icreportnext11.svg"
                          alt=""
                          title={translate("webAccessibility.confirmStep2")}
                        />
                      </div>
                      <span>
                        <span>{translate("reportProcess.spanStep2")}</span>
                      </span>
                    </div>
                    <div className="rcwcl_item">
                      <div className="bgr_item">
                        <img
                          src="/Images/icreportsearch11.svg"
                          alt=""
                          title={translate("webAccessibility.confirmStep3")}
                        />
                      </div>
                      <span>
                        <span>{translate("reportProcess.spanStep3")}</span>
                      </span>
                    </div>
                    <div className="rcwcl_item">
                      <div className="bgr_item">
                        <img
                          src="/Images/icreportinactive.svg"
                          alt=""
                          title={translate("webAccessibility.confirmStep4")}
                        />
                      </div>
                      <span>
                        <span>{translate("reportProcess.spanStep4")}</span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="rgwls_description">
                  {translate("guideline.descriptionStep")}
                </div>
              </div>
              <div
                className="rgw_button"
                title={translate("TooltipReport.Guideline.listtIcon1.button")}
              >
                <Link
                  to="/report/create"
                  className="rgwb_btn_detail"
                  // onClick={scrollTop}
                >
                  {translate("guideline.btn")}
                </Link>
              </div>
            </div>

            <div className="rgw_box">
              <span className="rgwb_title">{translate("guideline.term")} </span>
              <span className="rgwb_title">{translate("guideline.term2")}</span>
              <div className="rgwb_content">
                <div className="rgwbc_des">
                  <span>{translate("guideline.des1")}</span>
                </div>
                <div className="rgwbc_des des_left">
                  <span>{translate("guideline.des2")}</span>
                </div>
                <div className="rgwbc_des des_left">
                  <span>{translate("guideline.des3")}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {code === "kr" ? (
          <div className="rgw_policy_wrapp">
            <Wrap1200 className="contact_Wrap">
              <Flex className="wrap">
                <Flex className="wrap_left">
                  <div className="left_top">
                    <div className="top_img">
                      <img src="/Images/iconhelp.svg" alt="help" />
                    </div>
                    <div className="top_content">
                      {translate("guideline.complaint")}
                    </div>
                  </div>
                  <Flex className="wl_wrap">
                    <Flex className="block1">
                      <img src="/Images/car.png" alt="" />
                      <img src="/Images/icphonebigger48px.svg" alt="" />
                      <img src="/Images/iccomputer48px.svg" alt="" />
                    </Flex>
                    <Flex className="block2">
                      <div className="block2_content block2_textWrap block2_color">
                        {translate("guideline.customerCenter")}
                      </div>
                      <div className="block2_content block2_textWrap block2_color">
                        {translate("guideline.kiaCustomerCenter")}
                      </div>
                      <div className="block2_content block2_textWrap">
                        <a
                          className={width ? null : "bc_mobile"}
                          href={width ? "tel:080-600-6000" : null}
                        >
                          080-600-6000
                        </a>
                        <img
                          src="/Images/kia_logo.svg"
                          alt={translate("TooltipHome.iconHuyndai")}
                          title={translate("TooltipHome.iconHuyndai")}
                        />
                      </div>
                      <div className="block2_content block2_textWrap">
                        <a
                          className={width ? null : "bc_mobile"}
                          href={width ? "tel:080-200-2000" : null}
                        >
                          080-200-2000
                        </a>
                        <img
                          src="/Images/kia_black.svg"
                          alt={translate("TooltipHome.iconKia")}
                          title={translate("TooltipHome.iconKia")}
                        />
                      </div>
                      <div className="block2_content block2_textWrap">
                        <a
                          href="https://www.hyundai.com/kr/ko/ask"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {translate("guideline.customCenter")}{" "}
                          <img
                            src="/Images/kia_logo.svg"
                            alt={translate("TooltipHome.iconHuyndai")}
                            title={translate("TooltipHome.iconHuyndai")}
                          />
                        </a>
                      </div>
                      <div className="block2_content block2_textWrap">
                        <a
                          href="https://www.kia.com/kr/customer-service/default.html"
                          target="_blank"
                          rel="noopener noreferrer"
                          title={translate("TooltipHome.icon1")}
                          alt={translate("TooltipHome.icon1Alt")}
                        >
                          {translate("guideline.customCenter")}{" "}
                          <img
                            src="/Images/kia_black.svg"
                            alt={translate("TooltipHome.iconKia")}
                            title={translate("TooltipHome.iconKia")}
                          />
                        </a>
                      </div>
                    </Flex>
                  </Flex>
                </Flex>
                <Flex className="wrap_right">
                  <Flex className="wr_item wr_item1">
                    <Link
                      to="/report/create"
                      title={translate(
                        "TooltipReport.Guideline.listtIcon2.icon1"
                      )}
                      // alt={translate(
                      //   'TooltipReport.Guideline.listtIcon2.icon1'
                      // )}
                    >
                      <img src="" alt="" style={{ display: "none" }} />
                    </Link>
                    <div className="wri_title">
                      {" "}
                      {translate("home.internet")}
                    </div>
                    <div className="wri_content">
                      {translate("home.internetdescription")}
                    </div>
                  </Flex>
                  <Flex className="wr_item wr_item2">
                    <div
                      className="wr_item_img wr_item2_img"
                      // alt={translate(
                      //   'TooltipReport.Guideline.listtIcon2.icon2'
                      // )}
                      title={translate(
                        "TooltipReport.Guideline.listtIcon2.icon2"
                      )}
                    >
                      <img src="" alt="" style={{ display: "none" }} />
                    </div>
                    <div className="wri_title">{translate("home.phone")}</div>
                    <a
                      className={
                        width ? "wri_content" : "wri_content wri_mobile"
                      }
                      href={width ? "tel:02-3464-3500" : null}
                    >
                      02-3464-3500
                    </a>
                  </Flex>
                  <Flex className="wr_item wr_item3">
                    <div
                      className="wr_item_img wr_item3_img"
                      // alt={translate(
                      //   'TooltipReport.Guideline.listtIcon2.icon3'
                      // )}
                      title={translate(
                        "TooltipReport.Guideline.listtIcon2.icon3"
                      )}
                    >
                      <img src="" alt="" style={{ display: "none" }} />
                    </div>
                    <div className="wri_title">{translate("home.fax")}</div>
                    <div className="wri_content">02-3464-8813</div>
                  </Flex>
                  <Flex className="wr_item wr_item4">
                    <div
                      className="wr_item_img wr_item4_img"
                      // alt=''
                      title={translate(
                        "TooltipReport.Guideline.listtIcon2.icon4"
                      )}
                      id="wr_item4_img"
                      tabIndex="0"
                      onKeyPress={handleKeyPressPopup}
                      onClick={() => {
                        setVisible(true);
                        document.getElementById("wr_item4_img").blur();
                      }}
                    >
                      <img src="" alt="" style={{ display: "none" }} />
                    </div>
                    <div className="wri_title">
                      {translate("home.postMail")}
                    </div>
                    <div className="wri_content">
                      {translate("home.postMailDescription")}
                    </div>
                    <Modal
                      className="someModal"
                      maskClosable={false}
                      visible={visible}
                      footer={null}
                      centered
                      width="450px"
                      onCancel={e => {
                        e.stopPropagation();
                        setVisible(false);
                      }}
                      closeIcon={
                        <img
                          src="/Images/icclose36px.svg"
                          alt={translate("webAccessibility.closeButton")}
                        />
                      }
                    >
                      <p className="title">
                        {translate("global.address-title")}
                      </p>
                      <p className="content">
                        {translate("global.address-content")}
                      </p>
                    </Modal>
                  </Flex>
                </Flex>
              </Flex>
            </Wrap1200>
          </div>
        ) : (
          <Flex className="btn_totop">
            <a
              href="javascript: void(0)"
              tabIndex="0"
              id="backtotopguide"
              onClick={scrollTop}
              onKeyPress={handleKeyPress}
            >
              <img
                src="/Images/icarrowupward48px.svg"
                alt={translate("TooltipHome.btnToTop")}
                title={translate("TooltipHome.btnToTop")}
              />
            </a>
          </Flex>
        )}
        {code === "kr" ? (
          <Flex className="btn_totop2">
            <a
              href="javascript: void(0)"
              tabIndex="0"
              id="backtotopguide_kr"
              onClick={scrollTopkr}
              onKeyPress={handleKeyPress}
            >
              <img
                src="/Images/icarrowupward48px.svg"
                alt={translate("TooltipHome.btnToTop")}
                title={translate("TooltipHome.btnToTop")}
              />
            </a>
          </Flex>
        ) : null}
      </div>
    </div>
  );
};
export default withLocalize(GuideLine);
