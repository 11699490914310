import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { Upload, Modal } from 'antd';
import { confirmDelete, confirm } from '../../../components/Modal';
import { API_SERVER_UPLOAD, HYUNDAI_SITE_ID } from '../../../constants/index';
import { downloadFileUpload } from '../../../services/api';
import './style.scss';
import { showToastError } from '../../../components/Modal';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
const action = `${API_SERVER_UPLOAD}file/upload/report?url=${HYUNDAI_SITE_ID}`;
const fileExtensionList = ['.DOC', '.PDF', '.DOCX', '.PPT', '.PPTX', '.XLS', '.XLSX', '.TXT', '.HWP', '.PNG', '.GIF', '.JPG', '.JPEG', '.BMP', '.MP4', '.AVI', '.MPG', '.MPEG', '.MOV', '.ZIP', '.RAR', '.7Z', '.MKV', '.M4A'];

class PicturesWall extends React.Component {
  constructor(props) {
    super(props);
    
  }

  state = {
    previewVisible: false,
    previewImage: '',
    fileList: [],
    urlFile: null,
    removeVal: null
  };
  handleCancel = () => this.setState({ previewVisible: false });
  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      urlFile: file.url
    });
  };
  handleChange = ({ file, fileList }) => {
    let indexLastOfDot = file.name.lastIndexOf('.');
    let fileExtension = file.name.substr(indexLastOfDot).toUpperCase();
    if (!this.includesCustom(fileExtensionList, fileExtension)) {
      showToastError(this.props.translate('createReport.uploadFileFail'), {
        autoClose: 5000
      });
      return false;
    }
    this.setState({ fileList }, () => {
      this.props.setValue('listFiles', fileList);
      if (file.status === 'done') {
        this.props.onUploadDone(file);
      }
    });
  };
  convertPathDownload = (path, fileName) => {
    let data = [];
    let newPath = '/' + fileName;
    data = path.split('/');
    if (data.length == 4) {
      newPath = '/' + data[1] + '/' + data[2] + '/' + fileName;
    }
    return newPath;
  }
  handleDownload = (e) => {
    let urlImage = this.convertPathDownload(e.response.data[0].folderPath, e.response.data[0].storedFileName);
    window.open(downloadFileUpload({ url: urlImage, fileName: e.response.data[0].storedFileName, originalFileName: e.response.data[0].originalFileName }), '_blank');
  }

  includesCustom = (data, currentElement) => {
    let result = false;
    data.map(element => {
      if (element == currentElement) {
        result = true;
      }
    });
    return result;
  }

  handleRemove = (e) => {
    
    return new Promise((resolve, reject) => {
      confirm({
        title: this.props.translate('reportRegistration.modalConfirmtitle'),
        content: this.props.translate('reportRegistration.modalConfirmcontent'),
        onOk: () => {
          this.props.onRemove(e);
          resolve(true);
        },
        onCacel: () => { reject(true); },
        cancelText: this.props.translate('reportRegistration.modalConfirm.btnNo'),
        okText: this.props.translate('reportRegistration.modalConfirmbtn')
      });
    });
  }
  beforeUpload = (file) => {
    let indexLastOfDot = file.name.lastIndexOf('.');
    let fileExtension = file.name.substr(indexLastOfDot).toUpperCase();
    if (fileExtensionList.includes(fileExtension)) {
      return true;
    } else {
      showToastError(this.props.translate('createReport.uploadFileFail'), {
        autoClose: 5000
      });
      return false;
    }
  }
  render() {
    const { previewVisible, previewImage, fileList, urlFile } = this.state;
    const {tooltipTitle, forLabel} = this.props;
    if (this.props.removeItems && fileList.length > 0) {
      this.setState({
        fileList: []
      });
    }
    const uploadButton = (
      <div className='upload_btn'>
        <img src="/Images/iccloudupload24px.svg" alt="" />
        <div className="ant-upload-text">{this.props.translate('reportReply.btnUpload')}</div>
      </div>
    );
    return (
      <div
      alt={tooltipTitle}
      title={tooltipTitle}
      className={`uploadMulti_file 
        ${fileList.length >= 6 ? 'moreSevenFiles' : null}
         ${fileList.length >= 2 ? 'moreTwoFiles' : null} 
         ${fileList.length >= 1 ? 'lessSevenFiles' : null}`}>
        <label className="inputTitle" htmlFor={forLabel}>{this.props.translate('reportReply.contentLable3')}</label>
        <div className="inputPlaceholder">{this.props.translate('reportReply.contentPlaceholder')}</div>
        <Upload
          accept="file_extension|audio/*|video/*|image/*|media_type"
          action={action}
          // onError={(e, res) => this.props.onError(e, res)}
          // onRemove={e => this.props.onRemove(e)}
          listType={'picture-card'}
          fileList={fileList} 
          multiple={true}
          // beforeUpload={() => false}
          onDownload={this.handleDownload}
          onPreview={this.handlePreview}
          onChange={this.handleChange}
          onRemove={e => this.handleRemove(e)}
        // beforeUpload={this.beforeUpload}
        >
          {uploadButton}
        </Upload>
        <Modal
          visible={previewVisible}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </div >
    );
  }
}
export default withLocalize(PicturesWall);
