import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Select } from 'antd';
import './style.scss';
const { Option } = Select;
const SanText = styled.div`
  font-family: HyundaiSansTextKR;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0;
`;
export default ({
  title,
  name,
  forLabel,
  textvalue,
  requirefield = false,
  class_selectbox,
  defaultValue,
  placeholder,
  data = [],
  title_value,
  labelTagTitle,
  titleSelect,
  generateLabel = true,
  onBlur = () => {},
  ...props
}) => {
  let [selected, setSelected] = useState(defaultValue || props.value);
  useEffect(() => {
    var selectComponent = document.getElementsByClassName(
      'ant-select-selection'
    );
    for (let index = 0; index < selectComponent.length; index++) {
      const element = selectComponent[index];
      element.removeAttribute('role');
    }
  }, []);
  // console.log(Object.values(data));
  // console.log({value: props.value});
  
  return (
    <div className={`tt-select-box-wrap ${class_selectbox}`}>
      <span className="title">
        {textvalue} <b hidden={!requirefield}>*</b>
      </span>
      <Select
        name={name}
        onBlur={onBlur}
        suffixIcon={<img src="/Images/arrow.png" alt="" />}
        className="select-box-field"
        style={{ width: '100%', height: '100%' }}
        defaultValue={defaultValue}
        placeholder={placeholder}
        generateLabel={generateLabel}
        {...props}
        // value={data && selected ? Object.values(data)[selected].value : null}
        onChange={value => {
          setSelected(value);
          props.onChange(value);
        }}
      >
        {data.map(item => {
          return (
            <Option value={item.id} key={item.key} title={item.value}>
              <span>{item.value}</span>
            </Option>
          );
        })}
      </Select>
      {/* {generateLabel &&
        <label
          htmlFor={forLabel}
          style={{
            position: 'absolute',
            clip: 'rect(0,0,0,0)',
            width: '1px',
            height: '1px',
            margin: '-1px',
            overflow: 'hidden'
          }}
        >
          {labelTagTitle}
        </label>
      } */}
      <select
        onChange={event => {
          setSelected(event.target.value);
          props.onChange(event.target.value);
        }}
        value={selected}
        // id={forLabel}
        style={{position:'absolute', clip: 'rect(0,0,0,0)', width: '1px', height:'1px', margin: '-1px', overflow:'hidden'}}
        title={titleSelect}
      >
        {(props.value !== undefined) ? (
          null
        ) : (
          <option selected></option>
        )}
        {data.map((item, index) => {
          return (
            <option selected={index == selected} value={item.id} key={item.id} title={item.value}>{item.value}</option>
          );
        })}
      </select>
    </div>
  );
};
