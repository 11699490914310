import React, { Component } from 'react';
import LayoutEthical from '../../../hocs/LayoutEthical';
import { Link } from 'react-router-dom';
import { withLocalize } from 'react-localize-redux';
import { Helmet } from 'react-helmet';
import HorizontalTimeline from 'react-horizontal-timeline';
import Loading from '../../../components/Loading';
import BreadCrumb from '../../../components/BreadCrumb';
import { apiListContentProgress } from '../../../services/api/index';
import { HYUNDAI_SITE_ID } from '../../../constants/index';
import './style.scss';

let tabIndex = 1;
class ProgressEthical extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      previous: 0,
      translateX: 0,
      loading: false,
      lstContentProgress: [],
    };
  }
  componentWillMount(){
    if (this.props.activeLanguage.code === 'en') {
      this.props.history.push('/');
    }
  }
  componentDidMount() {    

    this.loadAPI(this.props);
    setTimeout(() => {
      Array.from(document.getElementsByClassName('dot-label')).forEach((e) => {
        // e.setAttribute('tabindex', '0');
        e.setAttribute('title', e.innerText);
        e.addEventListener('keyup', event => {
          //on enter press
          if(event.shiftKey && event.keyCode == 9) {     
            
            let Buttonnext = document.getElementsByClassName('button-forward');
            if (Buttonnext) {
                Buttonnext[0].click();   
            }
            e.click();   
          }
          else if (event.keyCode === 9) {                 
            let Buttonnext = document.getElementsByClassName('button-forward');
            if (Buttonnext) {
                Buttonnext[0].click();   
            }
            e.click();    
          }
        });
        e.addEventListener('click', event => {
          // e.blur();
          setTimeout(() =>{
            let indexOfDot = e.getAttribute('tabindex');          
            let childContent = document.getElementsByClassName('list-content');
            if (childContent && childContent.length > 0) {            
              let aTag = childContent[0].getElementsByTagName('a');
              if (aTag && aTag.length > 0) {
                for (let index = 0; index < aTag.length; index++) {
                  const element = aTag[index];
                  element.setAttribute('tabindex', indexOfDot);
                }
              }
              let spanTag = childContent[0].getElementsByTagName('span');
              if (spanTag && spanTag.length > 0) {
                for (let index = 0; index < spanTag.length; index++) {
                  const element = spanTag[index];
                  element.setAttribute('tabindex', indexOfDot);
                }
              }
            }
            tabIndex=parseInt(indexOfDot);
          }, 10);          
        });
      });
      let Buttonnext = document.getElementsByClassName('button-forward');
      for (let index = 0; index < Buttonnext.length; index++) {
        const element = Buttonnext[index];
        element.setAttribute('title', this.props.translate('webAccessibility.buttonNextProgress'));
        element.setAttribute('alt', this.props.translate('webAccessibility.buttonNextProgress'));
      };
      let ButtonPrev = document.getElementsByClassName('button-back');
      for (let index = 0; index < ButtonPrev.length; index++) {
        const element = ButtonPrev[index];
        element.setAttribute('title', this.props.translate('webAccessibility.buttonPrevProgress'));
        element.setAttribute('alt', this.props.translate('webAccessibility.buttonPrevProgress'));
      }
    }, 800);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.activeLanguage.code !== nextProps.activeLanguage.code) {
      this.loadAPI(nextProps);
    }
  }

  componentDidUpdate() {
    if (this.props.activeLanguage.code === 'en') {
      this.props.history.push('/');
    }
    // this.reOrderTabIndex();
    let parentTagClass = ['header-wrap', 'BannerContent', 'breadcrumb_component', 'skip-link', 'blind' ];
    for (let i = 0; i < parentTagClass.length; i++) {
      this.setChildControlTabIndexSameAsParent(parentTagClass[i], 1);
    }
    tabIndex = 2;
    let eventsBarItems = document.getElementsByClassName('events-bar');
    if (eventsBarItems) {
      let timelinePoint = eventsBarItems[0].getElementsByTagName('li');
      if(timelinePoint && timelinePoint.length > 0) {
        for (let j = 0; j < timelinePoint.length; j++) {
          timelinePoint[j].setAttribute('tabindex', j + tabIndex);
        }          
      }
      
      let parentTagClass = ['btn_totop', 'sc-fzozJi', ];
      for (let i = 0; i < parentTagClass.length; i++) {
        this.setChildControlTabIndexSameAsParent(parentTagClass[i], timelinePoint.length + tabIndex);
      }
      document.getElementsByClassName('ant-select-selection')[0].setAttribute('tabindex', timelinePoint.length + tabIndex);
    }    
    
  }
  setChildControlTabIndexSameAsParent = (parentClass, tabIndex) => {    
    let parentElements = document.getElementsByClassName(parentClass);
    if(parentElements && parentElements.length > 0) {
      let ctls = ['a', 'button', 'input', 'textarea', 'select'];
      for (let i = 0; i < parentElements.length; i++) {
        const parentElement = parentElements[i];
        for (let j = 0; j < ctls.length; j++) {
          const ctrTagName = ctls[j];
          let controlsByTag = parentElement.getElementsByTagName(ctrTagName);
          if(controlsByTag && controlsByTag.length > 0) {
            for (let k = 0; k < controlsByTag.length; k++) {
              controlsByTag[k].setAttribute('tabindex', tabIndex);
            }          
          }
        }        
      }      
    }    
  }
  loadAPI = async props => {
    let rs = await apiListContentProgress(props.activeLanguage.code);

    if (rs.data && rs.data.data) {
      this.setState({
        lstContentProgress: rs.data.data.contentProgressList,
        loading: false,
        value: 0,
        previous: 0
      });
    } else {
      this.setState({
        // loading: true
      });
    }
  }

  getURLsFromString(str) {
    var re = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www\.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%\/.\w-]*)?\??(?:[-+=&;%@.\w]*)#?\w*)?)/gm; 
    var m;
    var arr = [];
    while ((m = re.exec(str)) !== null) {
      if (m.index === re.lastIndex) {
          re.lastIndex++;
      }
      arr.push(m[0]);
    }
    return arr;
  }

  renderContent = (index, contentProgress) => {
    if (contentProgress && contentProgress.length > 0) {
      return contentProgress[index].contentList.map((el, index) => {
        let href = '';
        const arrUrl = this.getURLsFromString(el.content);
        if (arrUrl.length > 0) {
          href = arrUrl[0];
        }
        return (
          <>
          <div key={index} className='lc_content_wrap'>
            {href === '' ? (
              <span className='lc_content' >{el.title}</span>
            ) : (
              <a href={href} className='lc_content' target='_blank' rel="noopener noreferrer" tabIndex={tabIndex}>{el.title}</a>
            )}
          </div>
          </>
        );
      });
    }
    return '';
  };

  render() {
    const { translate } = this.props;
    const { lstContentProgress, loading } = this.state;
    // const { path } = this.props.match;
    // const arrayPaths = path.split("/");
    // const x = 0;

    return (
      <LayoutEthical>
        <div className="container" id='contentProgress'>
          <div className="EthicalManagement_Progress">
            <div className="breadcumb">
            <Helmet>
              <title>{translate('helmet.progress')}</title>
            </Helmet>
              <BreadCrumb
                firstUrl={translate('progress.firstUrl')}
                firstRouter='/progress'
                secondUrl={translate('progress.secondUrl')}
                secondRouter='/progress'
              />
            </div>
            <div className="ew-post ">
              <div className="ew-post-detail">
                <h1 className="ew-post-title">{translate('title.progress')}</h1>
                <p className="ew-post-subject">{translate('title.quote')}</p>
              </div>
              <div className="ew-post-content">
                <div>
                  {/* Bounding box for the Timeline */}
                  <div
                    className="horizontal-timeline-wrap"
                    style={{ width: '100%', height: '130px', margin: '0 auto' }}
                  >
                    <HorizontalTimeline
                      alt='123'
                      styles={{
                        foreground: '#172663',
                        outline: '#172663'
                      }}
                      getLabel={function (data) {
                        return data;
                      }}
                      minEventPadding='50'
                      maxEventPadding='50'
                      index={this.state.value}
                      indexClick={index => {
                        this.setState({
                          value: index,
                          previous: this.state.value
                        });
                        var contentEl = document.getElementsByClassName('text-center')[0];
                        var h1Tag = contentEl.parentElement.getElementsByTagName('h1');
                        for (var i=0; i < h1Tag.length; i++) {
                          var el = h1Tag[i];
                          el.innerText = lstContentProgress.map(x => x.publicYear)[index];
                        }
                      }}
                      values={lstContentProgress.map(x => x.publicYear)}
                    />
                  </div>
                  <h1 className='hTagHidden'>{lstContentProgress.map(x => x.publicYear).length > 0 && lstContentProgress.map(x => x.publicYear)[0]}</h1>
                  <div className="text-center">
                    {loading == true ? (
                      <Loading isLoading={loading} />
                    ) : (
                        <div className="list-content">
                          {this.renderContent(
                            this.state.value,
                            lstContentProgress
                          )}
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutEthical>
    );
  }
}

export default withLocalize(ProgressEthical);
