import React, { useEffect, useState } from "react";
import styled from "styled-components";

import "./style.scss";
import { Select } from "antd";
const { Option } = Select;
const SanText = styled.span`
  font-family: HyundaiSansTextKR;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0;
`;

export default ({
  title,
  labelTagTitle,
  suffixIcon,
  forLabel,
  textvalue,
  titleSelect,
  class_selectbox,
  requirefield = false,
  defaultValue,
  onChange = () => {},
  onBlur = () => {},
  onFocus = () => {},
  onSearch = () => {},
  onDropdownVisibleChange = () => {},
  placeholder = "",
  data = [],
  name,
  errors,
  ...props
}) => {
  let [selected, setSelected] = useState(defaultValue);

  return (
    <div className={`tt-select-box-wrap ${class_selectbox}`}>
      <SanText className="title">
        {textvalue} <b hidden={!requirefield}>*</b>
      </SanText>
      <Select
        suffixIcon={<img src={suffixIcon} alt="" />}
        className="select-box-field"
        // showSearch
        style={{ width: "100%" }}
        placeholder={placeholder}
        // optionFilterProp="children"
        defaultValue={selected}
        onChange={(value, key) => {
          onChange(value, key);
          setSelected(value);
        }}
        onFocus={() => {
          onFocus();
        }}
        onBlur={onBlur}
        onSearch={onSearch}
        onDropdownVisibleChange={() => {
          onDropdownVisibleChange();
        }}
        name={name}
        {...props}
      >
        {data.map(item => {
          return (
            <Option value={item.id} key={item.id} title={item.publicDate}>
              {item.publicDate}
            </Option>
          );
        })}
      </Select>
      {errors ? <div className="error">{errors}</div> : null}
      {/* <label
        htmlFor={forLabel}
        style={{
          position: 'absolute',
          clip: 'rect(0,0,0,0)',
          width: '1px',
          height: '1px',
          margin: '-1px',
          overflow: 'hidden'
        }}
      >
        {labelTagTitle}
      </label> */}
      {/* <select
        onChange={event => {
          onChange(event.target.value);
          setSelected(event.target.value);
        }}
        value={defaultValue || selected}
        // id={forLabel}
        title={titleSelect}
        style={{
          position: 'absolute',
          clip: 'rect(0,0,0,0)',
          width: '1px',
          height: '1px',
          margin: '-1px',
          overflow: 'hidden'
        }}
      >
        {data.map((item, index) => {
          return (
            <option value={item.id} key={item.id} title={item.publicDate}>
              {item.publicDate}
            </option>
          );
        })}
      </select> */}
    </div>
  );
};
