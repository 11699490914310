import React, { useState, useEffect } from 'react';
import BannerContent from '../../components/BannerContent';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import styled from 'styled-components';
import { withLocalize } from 'react-localize-redux';
import './style.scss';

const Flex = styled.div`
  display: flex;
`;

const scrollTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
  document.getElementById('backtotop').blur();
};
const handleKeyPress=(event) =>{
  if(event.key === 'Enter'){      
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
};

const LayoutEthical = ({
  languages,
  activeLanguage,
  translate,
  setActiveLanguage,
  ...props
}) => {
  const { children } = props;
  let [overFlow, setOverFlow] = useState(null);

  return (
    <>
    <a class="skip-link" href='#contentEthical' tabIndex='1'>{translate('webAccessibility.skipLink')}</a>
    <div className={`LayoutEthical ${overFlow}`} >
      <div className='el_header_wrap'>
      <Header setOverFlow={setOverFlow} type={translate('progress.titlePage')}/>
      </div>
      <BannerContent
        background="/Images/new_bg.png"
        title={translate('progress.titleBanner')}
        content={translate('progress.desBanner')}
      />
      <a href='#contentEthical' id="contentEthical" className='blind' tabIndex='1'>{translate('webAccessibility.skipLinkBlind')}</a>
      <div className="LayoutEthical_content">{children}</div>
      <Flex className="btn_totop">
        <a href='javascript: void(0)' tabIndex="0" id='backtotop' onClick={scrollTop} onKeyPress={handleKeyPress}>
        <img
          src="/Images/icarrowupward48px.svg"
          alt={translate('TooltipHome.btnToTop')}
          title={translate('TooltipHome.btnToTop')}
        />
        </a>
      </Flex>
      <Footer />
    </div>
    </>
  );
};
export default withLocalize(LayoutEthical);
