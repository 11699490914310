import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { withLocalize } from 'react-localize-redux';
import "./style.scss";

const Layout = ({   languages,
  activeLanguage,
  translate,
  setActiveLanguage,
  ...props}) => {
  const { children } = props;
  let [overFlow, setOverFlow] = useState(null);
  useEffect(() => {
    // if (localStorage.getItem("languageCode") === "en") {
    //   document.getElementsByClassName("skip-link")[0].innerHTML =
    //   "Skip to main";
    // }
  }, []);
  return (
    <>
      <a class="skip-link" href="#homePage">{translate('webAccessibility.skipLink')}</a>
      <div className={`Layout_wrap ${overFlow}`}>
        <div className="lw_header_wrap">
          <Header setOverFlow={setOverFlow} />
        </div>
        <a href='#homePage' id="homePage" className='blind'>{translate('webAccessibility.skipLinkBlind')}</a>
        <div className="layout_content" >
          {children}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default withLocalize(Layout);
