import React, { useState, useEffect } from 'react';
import BannerContent from '../../components/BannerContent';
import { Link } from 'react-router-dom';
import BreadCrumb from '../../components/BreadCrumb';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { withLocalize } from 'react-localize-redux';
import styled from 'styled-components';
import './style.scss';

const Flex = styled.div`
  display: flex;
`;

const scrollTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
  document.getElementById('backtotopethics').blur();
};
const handleKeyPress=(event) =>{
  if(event.key === 'Enter'){      
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
};

const LayoutEthics = ({
  children,
  languages,
  activeLanguage,
  translate,
  setActiveLanguage,
  ...props
}) => {
  let [overFlow, setOverFlow] = useState(null);
  useEffect(()=> {
    // if(localStorage.getItem('languageCode') === 'en'){
    //   document.getElementsByClassName('skip-link')[0].innerHTML = 'Skip to main';
    // }
  },[]);

  return (
    <>
    <a class="skip-link" href='#contentEthics'>{translate('webAccessibility.skipLink')}</a>
    <div className={`LayoutEthics ${overFlow}`} >
      <div className='els_header_wrap'>
      <Header setOverFlow={setOverFlow} type={translate('ethicStandard.titlePage')}/>
      </div>
      <BannerContent
        title={translate('ethicStandard.newTitle')}
        content={translate('ethicStandard.desBanner')}
        background="/Images/bgr_ethics.png"
      />
      <a href='#contentEthics' id="contentEthics" className='blind'>{translate('webAccessibility.skipLinkBlind')}</a>
      <div className="LayoutEthics_content">
        <BreadCrumb
          firstUrl={translate('ethicStandard.newUrl')}
          firstRouter='/ethics-standard'
          // secondUrl={translate('ethicStandard.secondUrl')}
          // secondRouter='/ethics-standard'
        />
        {/* <div className="lc_title">{translate('ethicStandard.title')}</div> */}
        <div className="lc_description">
          {translate('ethicStandard.description')}
        </div>
        {children}
      </div>
      <Flex className={`btn_totop ${activeLanguage.code === 'en' ? 'btn_totop2' : null}`}>
      <a href='javascript: void(0)' tabIndex="0" id='backtotopethics' onClick={scrollTop} onKeyPress={handleKeyPress}>
        <img
          src="/Images/icarrowupward48px.svg"
          alt={translate('TooltipHome.btnToTop')}
          title={translate('TooltipHome.btnToTop')}
        />
        </a>
      </Flex>
      <Footer />
    </div>
    </>
  );
};
export default withLocalize(LayoutEthics);
