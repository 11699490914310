import React, { useEffect, useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import SelectBox from '../SelectBox';
import queryString from 'querystring';
import './style.scss';
const TranslateLanguage = ({
  languages,
  activeLanguage,
  setActiveLanguage,
  translate,
  ...props
}) => {
  let query = queryString.parse(props.location.search.slice(1, props.location.search.length));
  useEffect(() => {
    let langCode = localStorage.getItem('languageCode');
    if (langCode === 'kr' || langCode === 'en') {
      setActiveLanguage(langCode);
    }

    if (props.location.search && props.location.search != '') {
      if (query && query.lang) {
        if (query.lang === 'kr' || query.lang === 'en') {
          setActiveLanguage(query.lang);
          localStorage.setItem('languageCode', query.lang);
          props.history.push(props.location.pathname);          
        } 
      } 
    }

    // const selectLanguage = document.getElementsByTagName("SELECT");
    // selectLanguage[0].setAttribute('title', translate('webAccessibility.selectLanguage'));
    // console.log('----------',document.getElementsByTagName("LABEL"));
    // let label = document.getElementsByTagName("LABEL")[0];
    // if (label.parentNode) {
    //   label.parentNode.removeChild(label);
    // }
  }, []);
  
  const languageOptions = languages.map((item, index) => {
    return { id: index, value: item.name };
  });
  
  const onLanguageChange = value => {
    setActiveLanguage(languages[value].code);
    localStorage.setItem('languageCode', languages[value].code);
    // window.location.reload();
  };
  
  return (
    <>
    <Helmet htmlAttributes={{ lang : activeLanguage.code === 'kr' ? 'ko' : activeLanguage.code }}/>
    <SelectBox
      suffixIcon={<img src="/Images/Component63197.png" alt={translate('webAccessibility.changeLanguage')} title={translate('webAccessibility.changeLanguage')}/>}
      class_selectbox='changeLanguage'
      style={{ Width: '90%' }}
      value={localStorage.getItem('languageCode') ? (localStorage.getItem('languageCode') == 'kr' ? 0 : 1) : 0}
      data={languageOptions}
      onChange={onLanguageChange}
      titleSelect={translate('webAccessibility.selectLanguage')}
      generateLabel={false}
    />
    </>
  );
};

export default withRouter(withLocalize(TranslateLanguage));
