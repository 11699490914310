import "react-datepicker/dist/react-datepicker.css";
import "./style.scss";
import React, { useState, useEffect } from "react";
import { Link, Prompt } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { withLocalize } from "react-localize-redux";
import { Helmet } from "react-helmet";
import { v4 as uuidv4 } from "uuid";
import { Radio, Checkbox, Modal } from "antd";
import * as yup from "yup";
import moment from "moment";
import { registerLocale } from "react-datepicker";
import el from "date-fns/locale/ko"; // the locale you want
import styled from "styled-components";
import { Beforeunload } from "react-beforeunload";
import { showToastError } from "../../../components/Modal";
import Loading from "../../../components/Loading";
import BannerContent from "../../../components/BannerContent";
import BreadCrumb from "../../../components/BreadCrumb";
import Input from "../../../components/Input";
import TextArea from "../../../components/TextArea";
import Upload from "../../../components/Input/Upload";
import DatePicker from "../../../components/DatePickerInput";
import DateInput from "../../../components/DateInputMask";
import SelectBox from "../../../components/SelectBox";
import Hangul from "hangul-js";
import { GBSize, MBSize, HYUNDAI_SITE_ID } from "../../../constants";
import { MESSAGE } from "../../../constants/error";
import { listCountryCode } from "../../../constants/ListCountryCode";
import PrivacyPolicyPop from '../PrivacyPolicyPop';
import {
  AddReport,
  commonDeleteFile,
  PopupReport,
  getListSubsidiary
} from "../../../services/api";

registerLocale("ko", el); // register it with the name you want
const Flex = styled.div`
  display: flex;
`;

const RealName = ({ history, activeLanguage, translate, ...props }) => {
  let schema = {
    subsidiaryCd: yup.string(),
    titleReport: yup
      .string()
      .required("MESSAGE.MSG7")
      // .typeError(MESSAGE.MSG10)
      .max(1000, MESSAGE.MAXLENGTH_1000),
    content: yup
      .string()
      .required(MESSAGE.MSG7)
      .max(16000, MESSAGE.MAXLENGTH_16000),
    Question04: yup.string().max(500, MESSAGE.MAXLENGTH_500),
    Question05: yup.string().max(500, MESSAGE.MAXLENGTH_500),
    // .typeError(MESSAGE.MSG10),
    // .max(5000, MESSAGE.MAXLENGTH_5000),
    name: yup
      .string()
      // .typeError(MESSAGE.MSG10)
      .max(50, MESSAGE.MAXLENGTH_50)
      .when("kindCustomer", {
        is: "R",
        then: yup
          .string()
          // .typeError(MESSAGE.MSG10)
          .required(MESSAGE.MSG7),
        otherwise: yup.string().notRequired()
      }),
    email: yup
      .string()
      .email()
      .required(MESSAGE.MSG7)
      .typeError(MESSAGE.MSG10)
      .max(100, MESSAGE.MAXLENGTH_100),
    country: yup.string().max(30, MESSAGE.MAXLENGTH_30_EN),
    region: yup.string().max(20, MESSAGE.MAXLENGTH_20_EN),
    mobilePhone: yup
      .string()
      // .typeError(MESSAGE.MSG10)
      .when("kindCustomer", {
        is: "R",
        then: yup
          .string()
          // .typeError(MESSAGE.MSG10)
          .required(MESSAGE.MSG7),
        otherwise: yup.string().notRequired()
      })
      .max(20, MESSAGE.MAXLENGTH_20)
      .test("mobilePhone", MESSAGE.MSG40_1, (value = "") => {
        if (value.length > 0 && value.length < 9) {
          return false;
        }
        return true;
      }),
    telePhone: yup
      .string()
      .max(20, MESSAGE.MAXLENGTH_20)
      .test("telePhone", MESSAGE.MSG40_1, (value = "") => {
        if (value.length > 0 && value.length < 9) {
          return false;
        }
        return true;
      }),
    // mobileZone: yup.string().max(MESSAGE.MAXLENGTH_5_EN),
    // telZone: yup.string().max(MESSAGE.MAXLENGTH_5_EN),
    kindCustomer: yup.string(),
    listFiles: yup.array(),
    dateOfBirth: yup.date().nullable()
  };
  // const placeContent =
  //   '품질 또는 서비스 불편사항은 \n관련부문으로 이관되어 처리 되는바 \n신속한 처리를 위해 고객센터 \n(현대 : 080-600-6000, 기아 : 080-200-2000) \n또는 당사 홈페이지 Q&A 나 온라인상담을 이용해 주시기 바랍니다. \n• 작성요령(최대한 자세히 기록 바랍니다.) \n① 제보하고자 하는 내용은 : \n   □ 내가 했거나 당한 일임, □ 내가 직접 보거나 들음, \n   □ 직장동료/업체등에게 들음, □ 소문으로 알게 된 내용임 등 \n② 제공자(업체)는 : OO 업체 대표 홍길동, 영업과장 나일등 \n③ 제보대상은 : OOO 사업부 OOO팀 나기아 차장, 너현대 과장 \n④ 부정비리 상세내용은(시기, 장소, 규모) \n   □ \'17년 12월 경 나기아 차장에게 OO업체 OOO 대표가 현금 OO만원을  \n흰봉투에 넣어 OO횟집에서...(나기아 차장 와이프의 통장으로...) \n       □ \'17년 1월 1일 너현대 과장에게 OO업체 OO과장이 역삼동 OO룸싸롱에서 접대... \n⑤ 접대/향응 제공 목적은 \n   : OO 프로젝트 수주 특혜 목적/입찰정보 제공 요청 등 \n⑥ 기타내용: \n 해당 내용을 알것으로 예상되는 사람은 OOO이고, 연락처는 010-1234-1234입니다. 사실 확인을 위한 방법은 OOO입니다. 등의 추가내용 기입 \n※ 제보내용(실명,특정업체명 등)이 구체적이지 않을 경우 조사가 지연되거나 보류될 수 있으니 최대한 자세히 기록바라며, 근거없는 비방 및 험담 등은 종결 처리 될 수 있음을 양지 바랍니다.';
  // const placeContent1 =
  //   '관련 문서 이름/위치, 전산 시스템 이름, 협력업체 인원 정보 등';
  // const textBanner =
  //   '“보내주신 의견, 소중히 듣겠습니다.” \n사이버감사실에서는 내용 확인후 처리 결과를 안내 해 드립니다. \n제보 내용, 범위에 따라 조사 개시일, 조사 기간이 달라질 수 있습니다.';
  const { location } = history;
  const { code } = activeLanguage;
  const { pathname } = location;
  let [loading, setLoading] = useState(false);
  let [kindCustomerValue, setKindCustomerValue] = useState("R");
  let [visible, setVisible] = useState(false);
  let [modalStart, setModalStart] = useState(true);
  // let [checkedAll, setCheckedAll] = useState(false);
  let [dataListCountryCode, setDataListCountryCode] = useState([]);
  let [contentPopup, setContentPopup] = useState([]);
  // let [contentBanner] = useState(textBanner);
  let [birthday, setBirthday] = useState(null);
  // let [placeHolder, setPlaceHolder] = useState(placeContent);
  // let [placeHolder1, setPlaceHolder1] = useState(placeContent1);
  const [totalFileSize, setTotalFileSize] = useState(0);
  const [isValidFile, setIsValidFile] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [rfErrorMsg1, setrfErrorMsg1] = useState(null);
  const [rfErrorMsg2, setrfErrorMsg2] = useState(null);
  const [errorMobile, setErrorMobile] = useState(null);
  const [errorDOB, setErrorDOB] = useState(null);
  const [errorDOB2, setErrorDOB2] = useState(null);
  const [errorDOB3, setErrorDOB3] = useState(null);
  const [listSub, setListSub] = useState([]);
  const [selectSubsidiary, setSelectSubsidiary] = useState("");
  let [firstLoad, setFirstLoad] = useState(true);
  let [disableContent, setDisableContent] = useState(false);
  let [disablePhone, setDisablePhone] = useState(false);

  const date1 = new Date('2024-04-29');//4/30
  const date2 = new Date('2024-05-11');
  const date3 = new Date();


  // let [listFiles, setListFiles] = useState([]);
  const {
    register,
    handleSubmit,
    errors,
    // setError,
    control,
    clearError,
    watch,
    setValue,
    getValues,
    reset
    // formState,
    // triggerValidation
  } = useForm({
    validateCriteriaMode: "all",
    mode: "onChange",
    validationSchema: yup.object().shape(schema),
    reValidateMode: "onChange",
    defaultValues: {
      listFiles: [],
    }
  });

  const scrollTop = () => {
    window.scrollTo({ top: 0 });
    document.getElementById("backtotoprealname").blur();
  };
  const handleKeyPress = event => {
    if (event.key === "Enter") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };
  let kindCustomerWatcher = watch("kindCustomer", kindCustomerValue);

  useEffect(() => {
    getListSubsidiary(code).then(res => {
      const data = res.data.data;
      const options = data.map(item => {
        if (item) {
          return {
            value: item.value,
            id: item.code
          };
        }
      });
      setListSub(options);
      if (selectSubsidiary) {
        setSelectSubsidiary(options.type);
      }
    });
    const countryCode = listCountryCode.map(item => {
      return {
        code: item.code,
        value: item.name + " (" + item.code + ")",
        id: uuidv4()
      };
    });
    setDataListCountryCode(countryCode);
    setLoading(true);
    PopupReport(code)
      .then(response => {
        const data = response.data.data;
        const contentsList = data.contentsList;
        setContentPopup(contentsList[0]);
        setLoading(false);
      })
      .catch(err => {});

    document.getElementById("clear-error-input").click();
  }, [code]);
  useEffect(() => {
    setrfErrorMsg1("");
    setrfErrorMsg2("");
    clearError(["email", "titleReport", "content", "name"]);
    // reset();
    if (!errors.mobilePhone) {
      setDisablePhone(true);
    }
    if (!errors.content) {
      setDisableContent(true);
    }
  }, [code]);

  useEffect(() => {
    setValue("dateOfBirth", null);
  }, activeLanguage.code);

  useEffect(() => {
    if (firstLoad) {
      setDisableContent(true);
      setFirstLoad(false);
    }
    document.getElementById("submitForm").addEventListener("click", function() {
      setDisableContent(false);
      setDisablePhone(false);
    });
    // let e = document.getElementsByClassName('ant-checkbox');
    // for (let index = 0; index < e.length; index++) {
    //   const element = e[index];
    //   element.setAttribute('tabindex', '0');
    // }
    // let radioCheck = document.getElementsByClassName('ant-radio');
    // for (let index = 0; index < radioCheck.length; index++) {
    //   const element = radioCheck[index];
    //   element.setAttribute('tabindex', '0');
    // }
  });

  useEffect(() => {
    // var checkBoxComponent1 = document.getElementsByClassName('rf_checkbox1');
    // for (let index = 0; index < checkBoxComponent1.length; index++) {
    //   const element = checkBoxComponent1[index];
    //   element.setAttribute('title', translate('webAccessibility.labelCheckBox1'));
    // }
    // var checkBoxComponent2 = document.getElementsByClassName('rf_checkbox2');
    // for (let index = 0; index < checkBoxComponent2.length; index++) {
    //   const element = checkBoxComponent2[index];
    //   element.setAttribute('title', translate('webAccessibility.labelCheckBox2'));
    // }
    // var checkBoxComponentAll = document.getElementsByClassName('rf_checkboxAll');
    // for (let index = 0; index < checkBoxComponentAll.length; index++) {
    //   const element = checkBoxComponentAll[index];
    //   element.setAttribute('title', translate('webAccessibility.labelCheckBoxAll'));
    // }
    var InputcheckBoxComponent = document.getElementsByClassName(
      "ant-checkbox-input"
    );
    for (let index = 0; index < InputcheckBoxComponent.length; index++) {
      const element = InputcheckBoxComponent[index];
      element.setAttribute("id", "check" + index);
    }
    setTimeout(() => {
      let uploadBtn = document.querySelectorAll("input[type=file]");
      uploadBtn[0].setAttribute("title", translate("createReport.uploadFile"));
      let a = document.getElementsByClassName("select-box-field");
      for (let index = 0; index < a.length; index++) {
        const element = a[index];
        if (element) {
          element.setAttribute("area-hidden", "true");
        }
      }
    }, 1000);
    let checkBox1 = document.getElementById("check0");
    checkBox1.setAttribute(
      "title",
      translate("webAccessibility.labelCheckBox1")
    );
  }, []);

  const onSubmit = ({
    subsidiaryCd,
    titleReport,
    content,
    name,
    dateOfBirth,
    email,
    country,
    region,
    mobilePhone,
    mobileZone,
    telePhone,
    telZone,
    listFiles,
    kindCustomer,
    Question01,
    Question02,
    Question03,
    Question04,
    Question05,
    agree1,
    agree2
  }) => {
    listFiles = listFiles.map(item => {
      return {
        originalFileName: item.name,
        storedFileName: item.response.data[0].storedFileName,
        fileSize: item.size
      };
    });
    const checked1 = agree1 === 'agree';
    const checked2 = agree2 === 'agree';
    if (!checked1 && !checked2) {
      setrfErrorMsg1("true");
      setrfErrorMsg2("true");
      showToastError(translate("createReport.errcheck"), {
        autoClose: 5000
      });
      return;
    } else if (!checked1 && checked2) {
      setrfErrorMsg1("true");
      setrfErrorMsg2(null);
      showToastError(translate("createReport.errcheck1"), {
        autoClose: 5000
      });
      return;
    } else if (!checked2 && checked1) {
      setrfErrorMsg2("true");
      setrfErrorMsg1(null);
      showToastError(translate("createReport.errcheck2"), {
        autoClose: 5000
      });
      return;
    } else if (errorDOB || errorDOB2 || errorDOB3) {
      return;
    } else {
      setLoading(true);
      AddReport({
        subsidiaryCd: subsidiaryCd,
        url: HYUNDAI_SITE_ID,
        name: name
          ? Hangul.assemble(name)
          : translate("reportDetail.userAnonymous"),
        birthday: birthday
          ? moment(birthday, ["YYYY-MM-DD", "DD-MM-YYYY"], true).format(
              "YYYY-MM-DD"
            )
          : null,
        email: email,
        // country: dataListCountryCode.filter(item => item.id === telZone)[0].value,
        country,
        region,
        telephone: telePhone,
        mobile: mobilePhone,
        title: titleReport ? Hangul.assemble(titleReport) : titleReport,
        content: content,
        language: code,
        sitesUrl: HYUNDAI_SITE_ID,
        telZone: telZone,
        mobileZone: mobileZone,
        // telZone: dataListCountryCode.filter(item => item.id === telZone)[0].code,
        // mobileZone: dataListCountryCode.filter(item => item.id === mobileZone)[0]
        //   .code,
        kindCustomer: kindCustomer,
        listFiles,
        surveyQuestion01: Question01,
        surveyQuestion02: Question02,
        surveyQuestion03: Question03,
        surveyQuestion04: Question04 ? Hangul.assemble(Question04) : Question04,
        surveyQuestion05: Question05 ? Hangul.assemble(Question05) : Question05
      })
        .then(response => {
          setLoading(false);
          const data = response;
          sessionStorage.setItem("code", data.data.data);
          history.push("/report/certificate");
        })
        .catch(err => {
          setLoading(false);
          showToastError(translate("createReport.errorMessage"), {
            autoClose: 5000
          });
        });
    }
  };
  const onUploadDone = e => {
    if (e.size > 0) {
      const downloadFileName = document.getElementsByClassName(
        "anticon-download"
      );
      var i;
      for (i = 0; i < downloadFileName.length; i++) {
        downloadFileName[i].setAttribute(
          "title",
          translate("createReport.download")
        );
      }
      const removeFileName = document.getElementsByClassName("anticon-delete");
      var y;
      for (y = 0; y < removeFileName.length; y++) {
        removeFileName[y].setAttribute(
          "title",
          translate("createReport.remove")
        );
      }
      // document.getElementsByClassName('anticon-delete')[0].setAttribute('title', translate('createReport.remove'));
      setTotalFileSize(totalFileSize + e.size);
      if ((totalFileSize + e.size) / GBSize > 1) {
        setIsValidFile(false);
        setErrorMsg(translate("createReport.errorUpload"));
      }
    }
  };
  const onRemove = e => {
    if (e.size > 0) {
      if (e.size / MBSize > 50) {
        setIsValidFile(true);
        setErrorMsg("");
      } else {
        setTotalFileSize(totalFileSize - e.size);
        if ((totalFileSize - e.size) / GBSize < 1) {
          setIsValidFile(true);
          setErrorMsg("");
        }
      }
    }
    const data = {
      originalFileName: e.originFileObj ? e.originFileObj.name : e.name
    };
    commonDeleteFile(data)
      .then(res => {})
      .catch(e => {});
  };
  const onErrorFile = res => {
    setIsValidFile(false);
    setErrorMsg(translate("createReport.uploadFileFail"));
  };
  const openExample = () => {
    window.open(
      "/example",
      "Example",
      "toolbar=no,location=no,status=no,menubar=no,scrollbars=1,resizable=1,width=768,height=870"
    );
  };
  // change validation messsage by language
  if (errors.titleReport) {
    if (errors.titleReport.types.required) {
      errors.titleReport.message = translate("report.titleRequired");
    } else if (errors.titleReport.types.max) {
      errors.titleReport.message = translate("report.titleMax");
    }
  }
  if (errors.content) {
    if (errors.content.types.required) {
      errors.content.message = translate("report.contentRequired");
    } else if (errors.content.types.max) {
      errors.content.message = translate("report.contentMax");
    }
  }
  if (errors.Question04) {
    if (errors.Question04.types.max) {
      errors.Question04.message = translate("report.surveyquestionMax");
    }
  }
  if (errors.Question05) {
    if (errors.Question05.types.max) {
      errors.Question05.message = translate("report.surveyquestionMax");
    }
  }
  if (errors.name) {
    if (errors.name.types.required) {
      errors.name.message = translate("report.nameRequired");
    } else if (errors.name.types.max) {
      errors.name.message = translate("report.nameMax");
    }
  }
  if (errors.email) {
    if (errors.email.types.required) {
      errors.email.message = translate("report.emailRequired");
    } else if (errors.email.types.max) {
      errors.email.message = translate("report.emailMax");
    } else if (errors.email.types.email) {
      errors.email.message = translate("report.emailTypeError");
    }
  }
  if (errors.mobilePhone) {
    errors.mobilePhone.message = translate("report.mobiphoneTest");
    if (errors.mobilePhone.types.required) {
      errors.mobilePhone.message = translate("report.mobiphoneRequired");
    } else if (errors.mobilePhone.types.max) {
      errors.mobilePhone.message = translate("report.mobiphoneMax");
    }
  }
  if (errors.telePhone) {
    errors.telePhone.message = translate("report.telePhoneTest");
    if (errors.telePhone.types.max) {
      errors.telePhone.message = translate("report.telePhoneMax");
    }
  }

  const validateDateInput = value => {
    setErrorDOB3(null);
    setErrorDOB2(null);
    setErrorDOB(null);
    //Check format
    if (
      !value ||
      JSON.stringify(value) === JSON.stringify([""]) ||
      JSON.stringify(value) === JSON.stringify(["__-__-____"]) ||
      JSON.stringify(value) === JSON.stringify(["____-__-__"])
    ) {
      return;
    }
    let mDate = moment(value, ["YYYY-MM-DD", "DD-MM-YYYY"], true);
    if (mDate.isValid()) {
      setErrorDOB2(null);
      //Check under 14 years old
      const now = moment(new Date());
      const duration = moment.duration(now.diff(mDate));
      if (duration.asYears() < 14) {
        setErrorDOB("true");
      } else if (mDate.year() <= 1900) {
        setErrorDOB3("true");
      } else {
        setErrorDOB(null);
      }
    } else {
      setErrorDOB2("true");
    }
  };
  const onFocusContent = () => {
    setDisableContent(false);
  };
  const onFocusPhone = () => {
    setDisablePhone(false);
  };
  return (
    <div className="report_realName">
      {
        date1 <= date3 && date2 >=date3
        ? <PrivacyPolicyPop />
        : null
      }
      <Beforeunload onBeforeunload={() => translate("report.leavePage")} />
      {/* <Prompt message={translate('report.leavePage')} /> */}
      {loading ? <Loading /> : null}
      <Helmet>
        <title>{translate("helmet.reportcreate")}</title>
      </Helmet>
      <BannerContent
        background="/Images/bgr_certificate.png"
        title={translate("reportRegistration.banner.title")}
        content={translate("reportRegistration.banner.content")}
        content_two={translate("reportRegistration.banner.content2")}
      />
      <div className="rr_content_wrap">
        <div className="wrap">
          <BreadCrumb
            firstRouter="/report/guideline"
            secondRouter="/report/create"
            firstUrl={translate("reportRegistration.banner.firsturl")}
            secondUrl={translate("reportRegistration.banner.title")}
          />
          <div className="rrcw_content">
            <div className="rrcwc_wrap">
              <div className="rrcwc_warning">
                <img src="/Images/warning.svg" alt="" />
                {kindCustomerWatcher === "R" ? (
                  <span>{translate("reportRegistration.notice")}</span>
                ) : (
                  <span>{translate("reportRegistration.notice_two")}</span>
                )}
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="content_report">
                  <div className="rrcwc_form">
                    <div className="rf_title">
                      <span> {translate("reportRegistration.titleHead")}</span>
                      <a
                        title={translate("webAccessibility.openSample")}
                        href="javascript:void(0)"
                        className={`rft_span ${
                          code === "en" ? "rft_span_en" : ""
                        }`}
                        onClick={() => {
                          openExample();
                        }}
                      >
                        <span>
                          {translate("reportRegistration.titleHead2")}
                        </span>
                      </a>
                    </div>
                    <Controller
                      as={<SelectBox />}
                      labelTagTitle={translate("webAccessibility.labelTagSub")}
                      title={translate("webAccessibility.selectboxSubsidiary")}
                      titleSelect={translate(
                        "webAccessibility.selectSubsidiary"
                      )}
                      forLabel={translate("reportRegistration.subsidiary")}
                      data={listSub}
                      class_selectbox="rf_list_subsidiary"
                      name="subsidiaryCd"
                      textvalue={translate("reportRegistration.subsidiary")}
                      // placeholder="select"
                      control={control}
                      onDropdownVisibleChange={() => {
                        let a = document.getElementsByClassName(
                          "ant-select-dropdown"
                        );
                        for (let index = 0; index < a.length; index++) {
                          const element = a[index];
                          if (element && element.children[0]) {
                            element.children[0].setAttribute(
                              "role",
                              "combobox"
                            );
                          }
                        }
                      }}
                    />
                    <Input
                      forLabel={translate("reportRegistration.title")}
                      classname_input="rf_input"
                      inputTitle={translate("reportRegistration.title")}
                      hidden={false}
                      name="titleReport"
                      errors={errors.titleReport && errors.titleReport.message}
                      register={register({})}
                      // type="search"
                    />
                    <div className="rf_des">
                      <TextArea
                        forLabel={translate("reportRegistration.content")}
                        // placeholder={translate('reportRegistration.textara')}
                        placeholder={
                          navigator.platform &&
                          /iPad|iPhone|iPod/.test(navigator.platform)
                            ? ""
                            : translate("reportRegistration.textara")
                        }
                        textTitle={translate("reportRegistration.content")}
                        hidden={false}
                        name="content"
                        errors={
                          disableContent == true
                            ? null
                            : errors.content && errors.content.message
                        }
                        defaultValue=""
                        register={register({})}
                        onFocus={onFocusContent}
                      />
                    </div>
                    <Controller
                      className="rf_upload"
                      inputTitle="첨부파일"
                      forLabel={translate("reportReply.contentLable3")}
                      tooltipTitle={translate(
                        "TooltipReport.Realname.btnUpload"
                      )}
                      as={<Upload />}
                      onError={(e, res) => onErrorFile(res)}
                      onUploadDone={e => onUploadDone(e)}
                      onRemove={e => onRemove(e)}
                      sitesUrl={pathname}
                      control={control}
                      hidden={false}
                      classname_input="rf_upload"
                      name="listFiles"
                      setValue={setValue}
                    />
                    {!isValidFile && (
                      <p
                        style={{
                          fontSize: 14,
                          color: "red",
                          marginTop: 10,
                          width: "100%"
                        }}
                      >
                        {errorMsg}
                      </p>
                    )}
                    <div className="rf_notice">
                      {translate("reportRegistration.uploadFile")}
                    </div>
                  </div>
                </div>
                <div className="rfrp_survey">
                  <div className="ac_title"></div>
                  <div className="rps_group">
                    <div className="rps_title">
                      {translate("reportRegistration.lable_1.title")}
                    </div>
                    <Controller
                      as={
                        <Radio.Group className="rps_gr">
                          <Radio value="1">
                            {translate("reportRegistration.lable_1.value_1")}
                          </Radio>
                          <Radio value="2">
                            {translate("reportRegistration.lable_1.value_2")}
                          </Radio>
                          <Radio value="3">
                            {translate("reportRegistration.lable_1.value_3")}
                          </Radio>
                          <Radio value="4">
                            {translate("reportRegistration.lable_1.value_4")}
                          </Radio>
                          <Radio value="5">
                            {translate("reportRegistration.lable_1.value_5")}
                          </Radio>
                          <Radio value="6">
                            {translate("reportRegistration.lable_1.value_6")}
                          </Radio>
                        </Radio.Group>
                      }
                      defaultValue={""}
                      onChange={e => {
                        return e[0].target.value;
                      }}
                      control={control}
                      name="Question01"
                    />
                  </div>
                  <div className="rps_group">
                    <div className="rps_title">
                      {translate("reportRegistration.lable_2.title")}
                    </div>
                    <Controller
                      as={
                        <Radio.Group className="rps_gr2">
                          <Radio value="1">
                            {translate("reportRegistration.lable_2.value_1")}
                          </Radio>
                          <Radio value="2">
                            {translate("reportRegistration.lable_2.value_2")}
                          </Radio>
                          <Radio value="3">
                            {translate("reportRegistration.lable_2.value_3")}
                          </Radio>
                          <Radio value="4">
                            {translate("reportRegistration.lable_2.value_4")}
                          </Radio>
                          <Radio value="5">
                            {translate("reportRegistration.lable_2.value_5")}
                          </Radio>
                        </Radio.Group>
                      }
                      defaultValue={""}
                      onChange={e => {
                        return e[0].target.value;
                      }}
                      control={control}
                      name="Question02"
                    />
                  </div>
                  <div className="rps_group">
                    <div className="rps_title">
                      {translate("reportRegistration.lable_3.title")}
                    </div>
                    <Controller
                      as={
                        <Radio.Group className="rps_gr">
                          <Radio value="1">
                            {translate("reportRegistration.lable_3.value_1")}
                          </Radio>
                          <Radio value="2">
                            {translate("reportRegistration.lable_3.value_2")}
                          </Radio>
                          <Radio value="3">
                            {translate("reportRegistration.lable_3.value_3")}
                          </Radio>
                          <Radio value="4">
                            {translate("reportRegistration.lable_3.value_4")}
                          </Radio>
                          <Radio value="5">
                            {translate("reportRegistration.lable_3.value_5")}
                          </Radio>
                          <Radio value="6">
                            {translate("reportRegistration.lable_3.value_6")}
                          </Radio>
                        </Radio.Group>
                      }
                      defaultValue={""}
                      onChange={e => {
                        return e[0].target.value;
                      }}
                      control={control}
                      name="Question03"
                    />
                  </div>
                </div>
                <div className="add_content">
                  <Input
                    forLabel={translate("reportRegistration.textbox_1")}
                    type="search"
                    classname_input="ac_input1"
                    inputTitle={translate("reportRegistration.textbox_1")}
                    name="Question04"
                    hidden={true}
                    register={register({})}
                    errors={errors.Question04 && errors.Question04.message}
                  />
                  <Input
                    style={code === "kr" ? null :{marginTop:"10px"}}
                    forLabel={translate("reportRegistration.textbox_2")}
                    type="search"
                    classname_input={`ac_input2 ${
                      code == "kr" ? "ac_inputKr" : null
                    }`}
                    inputTitle={translate("reportRegistration.textbox_2")}
                    // placeholder={translate(
                    //   'reportRegistration.choder_textbox_2'
                    // )}
                    name="Question05"
                    hidden={true}
                    register={register({})}
                    errors={errors.Question05 && errors.Question05.message}
                  />
                </div>
                <div className="report_information">
                  <div className="rrcwc_form">
                    <div className="rf_title">
                      {translate("reportRegistration.rpInfomation")}
                    </div>
                    <Input
                      forLabel={translate("reportRegistration.rpName")}
                      // type="search"
                      classname_input="rf_name"
                      inputTitle={translate("reportRegistration.rpName")}
                      disabled={kindCustomerWatcher !== "R"}
                      hidden={kindCustomerWatcher !== "R"}
                      name="name"
                      errors={errors.name && errors.name.message}
                      register={register({})}
                    />
                    <div className="rf_birthday">
                      <span className="rfb_label">
                        {translate("reportRegistration.rpDate")}
                      </span>
                      {kindCustomerWatcher === "R" ? (
                        <Controller
                          as={<DateInput />}
                          titlePlaceholder={
                            code === "kr" && kindCustomerWatcher === "R"
                              ? "생년월일 YYYY-MM-DD"
                              : code === "en" && kindCustomerWatcher === "R"
                              ? "Date of birth DD-MM-YYYY"
                              : ""
                          }
                          // inputTitle={translate('reportRegistration.rpDate')}
                          name="dateOfBirth"
                          value={birthday}
                          className={
                            kindCustomerWatcher === "A" ? "hideDateInput" : null
                          }
                          control={control}
                          mask={code === "kr" ? "9999-99-99" : "99-99-9999"}
                          placeholder={
                            code === "kr" && kindCustomerWatcher === "R"
                              ? "YYYY-MM-DD"
                              : code === "en" && kindCustomerWatcher === "R"
                              ? "DD-MM-YYYY"
                              : ""
                          }
                          disabled={
                            kindCustomerWatcher === "A" ? "disabled" : null
                          }
                          hidden={kindCustomerWatcher === "A"}
                          onChange={value => {
                            setBirthday(value);
                            validateDateInput(value);
                          }}
                          errors={
                            errorDOB
                              ? translate("reportRegistration.alertDate")
                              : errorDOB2
                              ? translate("reportRegistration.alertFutureDate")
                              : errorDOB3
                              ? translate("reportRegistration.alertOldDate")
                              : ""
                          }
                          beforeMaskedValueChange={(
                            newState,
                            oldState,
                            userInput
                          ) => {
                            var { value } = newState;
                            var selection = newState.selection;
                            setBirthday(value);
                            validateDateInput([newState.value]);
                            return {
                              value,
                              selection
                            };
                          }}
                        />
                      ) : (
                        <Input
                          forLabel={translate("reportRegistration.rpDate")}
                          inputTitle={translate("reportRegistration.rpDate")}
                          disabled={true}
                          hidden={true}
                          name="dateOfBirth"
                        />
                      )}

                      {/* <Controller
                        as={<DatePicker />}
                        code={code}
                        placeholderText={
                          //  kindCustomerWatcher === 'A' ? 'shdshdhsdhsd' : ''
                          code === 'kr' && kindCustomerWatcher === 'R' ? 'YYYY-MM-DD' :
                            code === 'en' && kindCustomerWatcher === 'R' ? 'DD-MM-YYYY' :
                              ''
                        }
                        className="datepicker_Popup"
                        locale={code === 'kr' ? 'ko' : 'vi'}
                        // inputTitle={translate('reportRegistration.rpDate')}
                        disabled={kindCustomerWatcher !== 'R'}
                        // classname_input="rf_datepicker"
                        name="dateOfBirth"
                        control={control}
                        // errors={errors.dateOfBirth && errors.dateOfBirth.message}
                        register={register({})}
                        selected={birthday}

                        onCalendarClose={() => {
                          document.querySelector(
                            'input[name="email"]'
                          ).focus();
                        }}
                        onChange={date => {
                          document.querySelector(
                            'input[name="dateOfBirth"]'
                          ).style.color = 'rgba(0, 0, 0, 0.65)';
                          let newdate = date[0];
                          setBirthday(newdate);
                          let e = moment(newdate);
                          let dateCur = moment().add('-14', 'y');
                          let dateIsBefore = moment(dateCurrent).isBefore(
                            e,
                            'day'
                          );
                          if (e && e.year() < 1900) {
                            setErrorDOB3('true');
                          } else {
                            setErrorDOB3(null);
                          }
                          if (dateIsBefore === true) {
                            setErrorDOB2('true');
                          } else {
                            setErrorDOB2(null);
                          }
                          if (e - dateCur > 0 && moment() - e >= 0) {
                            setErrorDOB('true');
                          } else {
                            setErrorDOB(null);
                          }
                        }}
                        errors={
                          errorDOB
                            ? translate('reportRegistration.alertDate')
                            : errorDOB2
                              ? translate('reportRegistration.alertFutureDate')
                              : errorDOB3
                                ? translate('reportRegistration.alertOldDate')
                                : ''
                        }
                      /> */}
                    </div>
                    <Input
                      forLabel={translate("reportRegistration.rpEmail")}
                      type="search"
                      classname_input="rf_email"
                      inputTitle={translate("reportRegistration.rpEmail")}
                      hidden={false}
                      name="email"
                      errors={
                        errors.email && errors.email.message
                          ? errors.email && errors.email.message
                          : translate("reportRegistration.alertUser")
                      }
                      errorsStyle={{top:"50px"}}
                      register={register({})}
                      // autoFocus={focus}
                    />
                    {code === "kr" ? null : (
                      <>
                        <Input
                          forLabel="Country"
                          type="search"
                          classname_input="rf_email"
                          inputTitle="Country (国家)"
                          hidden
                          name="country"
                          errors={errors.country && errors.country.message}
                          register={register({})}
                        />
                        <Input
                          forLabel="Region"
                          type="search"
                          classname_input="rf_email"
                          inputTitle="Region (地区)"
                          hidden
                          name="region"
                          errors={errors.region && errors.region.message}
                          register={register({})}
                        />
                      </>
                    )}

                    <div className="rf_phone">
                      {/* <Controller
                        as={<SelectBox />}
                        name="telZone"
                        requirefield={kindCustomerWatcher === 'R'}
                        textvalue="핸드폰번호"
                        class_selectbox="rfp_left"
                        defaultValue={
                          dataListCountryCode.filter(
                            item => item.code === '+82'
                          )[0] &&
                          dataListCountryCode.filter(
                            item => item.code === '+82'
                          )[0].id
                        }
                        key={dataListCountryCode}
                        control={control}
                        data={dataListCountryCode}
                        onChange={([selected]) => {
                          return selected;
                        }}
                        onBlur={() => {
                          triggerValidation('telZone');
                        }}
                        errors={errors.telZone && errors.telZone.message}
                      /> */}
                      {/* <span className='rfp_plus'>+</span>
                      <Input
                        hidden={kindCustomerWatcher !== 'R'}
                        inputTitle={translate('reportRegistration.mobilePhone')}
                        type="number"
                        typeDetail="phone"
                        classname_input="rfp_phone rfp_phone_zone"
                        name="mobileZone"
                        defaultValue='82'
                        key='mobileZone'
                        maxLength='4'
                        register={register({})}
                        errors={
                          errors.mobilePhone && errors.mobilePhone.message
                            ? errors.mobilePhone && errors.mobilePhone.message
                            : translate('reportRegistration.alertUser')
                        }
                      /> */}

                      <Input
                        forLabel={translate("reportRegistration.mobilePhone")}
                        hidden={kindCustomerWatcher === "A"}
                        inputTitle={translate("reportRegistration.mobilePhone")}
                        type="number"
                        typeDetail="phone"
                        classname_input="rfp_phone"
                        name="mobilePhone"
                        titlePlaceholder={code === "kr" ? "" : "821000000000"}
                        placeholder={code === "kr" ? "" : "821000000000"}
                        register={register({})}
                        autoFocus={false}
                        errors={
                          disablePhone == true
                            ? translate("reportRegistration.alertUser")
                            : errors.mobilePhone && errors.mobilePhone.message
                            ? errors.mobilePhone && errors.mobilePhone.message
                            : translate("reportRegistration.alertUser")
                        }
                        errorsStyle={{top:"50px"}}
                        onFocus={onFocusPhone}
                      />
                    </div>
                    <div className="rf_mobilephone">
                      {/* <Controller
                        as={<SelectBox />}
                        name="mobileZone"
                        textvalue="전화번호"
                        class_selectbox="rfp_left"
                        defaultValue={
                          dataListCountryCode.filter(
                            item => item.code === '+82'
                          )[0] &&
                          dataListCountryCode.filter(
                            item => item.code === '+82'
                          )[0].id
                        }
                        key={dataListCountryCode}
                        control={control}
                        data={dataListCountryCode}
                        onChange={([selected]) => {
                          return selected;
                        }}
                        errors={errors.telZone && errors.telZone.message}
                      /> */}
                      {/* <span className='rfp_plus'>+</span>
                      <Input
                        inputTitle={translate('reportRegistration.rpPhone')}
                        type="number"
                        typeDetail="phone"
                        classname_input="rfm_mobilephone rfm_mobilephone_zone"
                        hidden
                        name="telZone"
                        defaultValue='82'
                        key='telZone'
                        maxLength='4'
                        errors={errors.telePhone && errors.telePhone.message}
                        register={register({})}
                      /> */}
                      <Input
                        forLabel={translate("reportRegistration.rpPhone")}
                        inputTitle={translate("reportRegistration.rpPhone")}
                        type="number"
                        typeDetail="phone"
                        classname_input="rfm_mobilephone"
                        hidden
                        name="telePhone"
                        register={register({})}
                        errors={errors.telePhone && errors.telePhone.message}
                      />
                    </div>
                    <div className="rf_kind">
                      <Controller
                        as={
                          <Radio.Group>
                            <Radio
                              className="real_name"
                              value="R"
                              onClick={() => {
                                // document.querySelector(
                                //   'input[name="dateOfBirth"]'
                                // ).style.color = 'rgba(0, 0, 0, 0.65)';
                                // document.querySelector(
                                //   'input[name="dateOfBirth"]'
                                // ).style.color = 'transparent';
                                setValue('agree1', 'disagree');
                                setValue('agree2', 'disagree');
                                // let e = document.getElementsByClassName('real_name');
                                // e[0].children[0].removeAttribute('style');
                                // e[0].children[0].blur();
                              }}
                              onFocus={() => {
                                // let e = document.getElementsByClassName('real_name');
                                // e[0].children[0].style.border = '1px solid';
                              }}
                            >
                              {translate("reportRegistration.radio_1")}
                            </Radio>
                            <Radio
                              className="anonymous"
                              value="A"
                              onClick={() => {
                                document.querySelector(
                                  'input[name="telePhone"]'
                                ).value = "";
                                document.querySelector(
                                  'input[name="mobilePhone"]'
                                ).value = "";
                                // document.querySelector(
                                //   'input[name="dateOfBirth"]'
                                // ).style.color = '#fff';
                                document.querySelector(
                                  'input[name="name"]'
                                ).value = "";
                                setValue("dateOfBirth", null);
                                setValue('agree1', 'disagree');
                                setValue('agree2', 'disagree');
                                // setValue('agree1', 'agree');
                                // setValue('agree2', 'agree');
                                setVisible(true);
                                setErrorDOB(null);
                                setErrorDOB2(null);
                                setErrorDOB3(null);
                                setBirthday(null);
                                clearError([
                                  "email",
                                  "titleReport",
                                  "content",
                                  "mobilePhone",
                                  "name"
                                ]);
                                // let e = document.getElementsByClassName('anonymous');
                                // e[0].children[0].removeAttribute('style');
                              }}
                              onFocus={() => {
                                // let e = document.getElementsByClassName('anonymous');
                                // e[0].children[0].style.border = '1px solid';
                              }}
                            >
                              {translate("reportRegistration.radio_2")}
                            </Radio>
                          </Radio.Group>
                        }
                        defaultValue={"R"}
                        onChange={e => {
                          setKindCustomerValue(e[0].target.value);
                          clearError("mobilePhone");
                          return e[0].target.value;
                        }}
                        control={control}
                        name="kindCustomer"
                      />
                      <Modal
                        className="modalAnony"
                        visible={visible}
                        footer={null}
                        centered
                        maskClosable
                        width="588px"
                        onCancel={e => {
                          e.stopPropagation();
                          setVisible(false);
                          // let element = document.getElementsByClassName('anonymous');
                          // element[0].children[0].removeAttribute('style');
                        }}
                        closeIcon={
                          <img
                            src="/Images/icclose36px.svg"
                            alt={translate("webAccessibility.closeButton")}
                          />
                        }
                      >
                        <p className="title">
                          {translate("createReport.titleModal")}
                        </p>
                        <p className="content">
                          {translate("createReport.contentModal")}
                        </p>
                        <button
                          className="btn_click"
                          onClick={() => {
                            setVisible(false);
                            // let element = document.getElementsByClassName('anonymous');
                            // element[0].children[0].removeAttribute('style');
                          }}
                          alt={translate("TooltipReport.Realname.btnAnonymous")}
                          title={translate(
                            "TooltipReport.Realname.btnAnonymous"
                          )}
                        >
                          {translate("createReport.buttonModal")}
                        </button>
                      </Modal>
                    </div>
                  </div>
                </div>
                {code === "kr" ? (
                  <div className="report_confirm">
                    <div className="rrcwc_form">
                      <div className="rf_title">사이버감사실 이용을 위한 개인정보 수집 · 이용 및 제3자 제공 동의서</div>
                      <br />
                      <div className="rf_sub_content">
                        <table className="rf_table">
                          <thead>
                            <tr>
                              <td>
                                동의서 작성 안내
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                현대자동차㈜, 기아㈜는 사이버감사실 이용을 위해 아래와 같이 개인정보를 수집·이용 및 제3자 제공하고자 합니다.<br/>
                                내용을 자세히 읽으신 후 동의 여부를 결정하여 주십시오.
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                        <div className="rf_sub_title">개인정보 수집 동의<br /></div>
                      {/* {kindCustomerWatcher === "R" ? (
                        <div className="rf_sub_title">개인정보 수집 동의<br /></div>
                      ) : null} */}
                        <div className="rf_sub_content">
                          <table className="rf_table">
                            <thead>
                              <tr>
                                <td>
                                  수집 및 이용 목적
                                </td>
                                <td>
                                  수집 및 이용 항목
                                </td>
                                <td>
                                  보유기간
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  제보/민원 처리 (실명 제보)
                                </td>
                                <td>
                                  [필수] 성명, 이메일 주소, 핸드폰번호<br/>
                                  [선택] 생년월일, 전화번호
                                </td>
                                <td rowSpan="2">
                                  제보 접수 후 조사 및 후속조치<br/>
                                  종결 시점까지
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  제보/민원 처리 (익명 제보)
                                </td>
                                <td>
                                  [필수] 이메일 주소
                                  [선택] 핸드폰번호, 전화번호
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div className="rf_comment">※ 위의 개인정보 수집 및 이용에 대한 동의를 거부하실 수 있습니다. 그러나, 동의 거부 시 제보 접수가 불가능합니다.</div>
                        </div>
                      {/* {kindCustomerWatcher === "R" ? (
                        <div className="rf_sub_content">
                          <table className="rf_table">
                            <thead>
                              <tr>
                                <td>
                                  수집 및 이용 목적
                                </td>
                                <td>
                                  수집 및 이용 항목
                                </td>
                                <td>
                                  보유기간
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  제보/민원 처리 (실명 제보)
                                </td>
                                <td>
                                  [필수] 성명, 이메일 주소, 핸드폰번호<br/>
                                  [선택] 생년월일, 전화번호
                                </td>
                                <td rowSpan="2">
                                  제보 접수 후 조사 및 후속조치<br/>
                                  종결 시점까지
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  제보/민원 처리 (익명 제보)
                                </td>
                                <td>
                                  [필수] 이메일 주소
                                  [선택] 핸드폰번호, 전화번호
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div className="rf_comment">※ 위의 개인정보 수집 및 이용에 대한 동의를 거부하실 수 있습니다. 그러나, 동의 거부 시 제보 접수가 불가능합니다.</div>
                        </div>
                      ) : null} */}
                        <div
                          className="rf_checkbox1_wrap"
                          id="rf_checkbox1_wrap"
                        >
                          <div className="rfcw_text">
                            <p>본인은 위와 같이 개인정보 수집·이용에 동의합니다.</p>
                            <div className="rfcw_check">
                              <Controller
                                style={{display:"flex"}}
                                as={
                                  <Radio.Group>
                                    <Radio
                                      className="rf_checkbox1"
                                      value='agree'
                                    >
                                      동의함
                                    </Radio>
                                    <Radio
                                      className="rf_checkbox1"
                                      value='disagree'
                                    >
                                    동의하지 않음
                                    </Radio>
                                  </Radio.Group>
                                }
                                onChange={e => {
                                  const isAgree = e[0].target.value === 'agree';
                                  if(isAgree) {
                                    setrfErrorMsg1(null);
                                  } else {
                                    setrfErrorMsg1("true");
                                    showToastError(
                                      translate("createReport.errcheck1"),
                                      {
                                        autoClose: 5000
                                      }
                                    );
                                  }
                                  return e[0].target.value;
                                }}
                                defaultValue={""}
                                control={control}
                                name="agree1"
                              />
                            </div>
                          </div>
                          <div className="rf_error">
                            {rfErrorMsg1 === "true"
                              ? translate("createReport.rfErrorMsg1")
                              : ""}
                          </div>
                        </div>
                      {/* {kindCustomerWatcher === "R" ? (
                        <div
                          className="rf_checkbox1_wrap"
                          id="rf_checkbox1_wrap"
                        >
                          <div className="rfcw_text">
                            <p>본인은 위와 같이 개인정보 수집·이용에 동의합니다.</p>
                            <div className="rfcw_check">
                              <Controller
                                as={
                                  <Radio.Group>
                                    <Radio
                                      className="rf_checkbox1"
                                      value='agree'
                                    >
                                      동의함
                                    </Radio>
                                    <Radio
                                      className="rf_checkbox1"
                                      value='disagree'
                                    >
                                    동의하지 않음
                                    </Radio>
                                  </Radio.Group>
                                }
                                onChange={e => {
                                  const isAgree = e[0].target.value === 'agree';
                                  if(isAgree) {
                                    setrfErrorMsg1(null);
                                  } else {
                                    setrfErrorMsg1("true");
                                    showToastError(
                                      translate("createReport.errcheck1"),
                                      {
                                        autoClose: 5000
                                      }
                                    );
                                  }
                                  return e[0].target.value;
                                }}
                                defaultValue={""}
                                control={control}
                                name="agree1"
                              />
                            </div>
                          </div>
                          <div className="rf_error">
                            {rfErrorMsg1 === "true"
                              ? translate("createReport.rfErrorMsg1")
                              : ""}
                          </div>
                        </div>
                      ) : null} */}
                      <div className="rf_sub_title">
                        "개인정보 제3자 제공 동의"
                        {/* {kindCustomerWatcher === "R"
                          ? "개인정보 제3자 제공 동의"
                          : "익명제보 시 유의사항"} */}
                      </div>

                      <div className="rgw_box">
                        <div className="rf_sub_content">
                            <table className="rf_table">
                              <thead>
                                <tr>
                                  <td>
                                    제공받는 자
                                  </td>
                                  <td>
                                    제공목적
                                  </td>
                                  <td>
                                    제공하는 항목
                                  </td>
                                  <td>
                                    보유기간
                                  </td>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td rowSpan="2">
                                    현대자동차 그룹사
                                  </td>
                                  <td rowSpan="2">
                                    제보 내용을 전달하여<br/>
                                    민원을 해결하기 위함
                                  </td>
                                  <td>
                                    실명 제보 시 <br/>
                                    [필수] 성명, 이메일 주소, 핸드폰번호<br/>
                                    [선택] 생년월일, 전화번호
                                  </td>
                                  <td rowSpan="2">
                                    제보 접수 후 조사 및 후속조치<br/>
                                    종결 시점까지
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    익명 제보 시<br/>
                                    [필수] 이메일 주소<br/>
                                    [선택] 핸드폰번호, 전화번호
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <table className="rf_content_table">
                              <thead>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="rf_content_table_left">
                                    ※ 현대자동차 그룹사 : 
                                  </td>
                                  <td>
                                    현대모비스, 현대트랜시스, 현대위아, 현대케피코, 현대건설, 현대엔지니어링, 현대제철, 현대글로비스,<br/>
                                    현대로템,현대오토에버, 현대캐피탈, 현대차증권, 현대비앤지스틸, 현대종합특수강, 현대스틸산업,<br/>
                                    현대도시개발, 현대엠시트, 현대아이에이치엘, 현대파텍스, 현대카드, 현대커머셜, 이노션,<br/>
                                    해비치 호텔&리조트, 현대엔지비, 현대서산농장, 지아이티, 지마린서비스
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <div className="rf_comment">
                              ※ 위의 개인정보 제3자 제공에 대한 동의를 거부하실 수 있습니다. 그러나, 동의 거부 시 제보 접수가 불가능합니다.
                            </div>
                        </div>
                        {/* {kindCustomerWatcher === "R" ? (
                          <div className="rf_sub_content">
                            <table className="rf_table">
                              <thead>
                                <tr>
                                  <td>
                                    제공받는 자
                                  </td>
                                  <td>
                                    제공목적
                                  </td>
                                  <td>
                                    제공하는 항목
                                  </td>
                                  <td>
                                    보유기간
                                  </td>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td rowSpan="2">
                                    현대자동차 그룹사
                                  </td>
                                  <td rowSpan="2">
                                    제보 내용을 전달하여<br/>
                                    민원을 해결하기 위함
                                  </td>
                                  <td>
                                    실명 제보 시 <br/>
                                    [필수] 성명, 이메일 주소, 핸드폰번호<br/>
                                    [선택] 생년월일, 전화번호
                                  </td>
                                  <td rowSpan="2">
                                    제보 접수 후 조사 및 후속조치<br/>
                                    종결 시점까지
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    익명 제보 시<br/>
                                    [필수] 이메일 주소<br/>
                                    [선택] 핸드폰번호, 전화번호
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <table className="rf_content_table">
                              <thead>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="rf_content_table_left">
                                    ※ 현대자동차 그룹사 : 
                                  </td>
                                  <td>
                                    현대모비스, 현대트랜시스, 현대위아, 현대케피코, 현대건설, 현대엔지니어링, 현대제철, 현대글로비스,<br/>
                                    현대로템,현대오토에버, 현대캐피탈, 현대차증권, 현대비앤지스틸, 현대종합특수강, 현대스틸산업,<br/>
                                    현대도시개발, 현대엠시트, 현대아이에이치엘, 현대파텍스, 현대카드, 현대커머셜, 이노션,<br/>
                                    해비치 호텔&리조트, 현대엔지비, 현대서산농장, 지아이티, 지마린서비스
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <div className="rf_comment">
                              ※ 위의 개인정보 제3자 제공에 대한 동의를 거부하실 수 있습니다. 그러나, 동의 거부 시 제보 접수가 불가능합니다.
                            </div>
                        </div>
                        ) : (
                          <div className="rgwb_content">
                            <div className="rgwbc_des rgwbc_ano">
                              <span className="rgwbcd_ano">
                                근거없는 비방 및 험담 등은 종결 처리될 수
                                있습니다.
                                <br /> 또한 내용에 따라 제보 결과를 안내해
                                드리지 않을 수 있습니다.
                              </span>
                            </div>
                          </div>
                        )} */}
                      </div>
                      <div className="rf_checkbox1_wrap">
                          <div className="rfcw_text">
                            본인은 위와 같이 개인정보 제3자 제공에 동의합니다.
                            <div className="rfcw_check">
                              <Controller
                                style={{display:"flex"}}
                                as={
                                  <Radio.Group>
                                    <Radio
                                      className="rf_checkbox1"
                                      value='agree'
                                    >
                                      동의함
                                    </Radio>
                                    <Radio
                                      className="rf_checkbox1"
                                      value='disagree'
                                    >
                                    동의하지 않음
                                    </Radio>
                                  </Radio.Group>
                                }
                                onChange={e => {
                                  const isAgree = e[0].target.value === 'agree';
                                  if(isAgree) {
                                    setrfErrorMsg2(null);
                                  } else {
                                    setrfErrorMsg2("true");
                                    showToastError(
                                      translate("createReport.errcheck1"),
                                      {
                                        autoClose: 5000
                                      }
                                    );
                                  }
                                  return e[0].target.value;
                                }}
                                defaultValue={''}
                                control={control}
                                name="agree2"
                              />
                            </div>
                          </div>
                          <div className="rf_error">
                            {rfErrorMsg2 === "true"
                              ? translate("createReport.rfErrorMsg2")
                              : ""}
                          </div>
                        </div>
                      {/* {kindCustomerWatcher === "R" ? (
                        <div className="rf_checkbox1_wrap">
                          <div className="rfcw_text">
                            본인은 위와 같이 개인정보 제3자 제공에 동의합니다.
                            <div className="rfcw_check">
                              <Controller
                                style={{display:"flex"}}
                                as={
                                  <Radio.Group>
                                    <Radio
                                      className="rf_checkbox1"
                                      value='agree'
                                    >
                                      동의함
                                    </Radio>
                                    <Radio
                                      className="rf_checkbox1"
                                      value='disagree'
                                    >
                                    동의하지 않음
                                    </Radio>
                                  </Radio.Group>
                                }
                                onChange={e => {
                                  const isAgree = e[0].target.value === 'agree';
                                  if(isAgree) {
                                    setrfErrorMsg2(null);
                                  } else {
                                    setrfErrorMsg2("true");
                                    showToastError(
                                      translate("createReport.errcheck1"),
                                      {
                                        autoClose: 5000
                                      }
                                    );
                                  }
                                  return e[0].target.value;
                                }}
                                defaultValue={''}
                                control={control}
                                name="agree2"
                              />
                            </div>
                          </div>
                          <div className="rf_error">
                            {rfErrorMsg2 === "true"
                              ? translate("createReport.rfErrorMsg2")
                              : ""}
                          </div>
                        </div>
                      ) : null} */}
                    </div>
                  </div>
                ) : (
                  <div className="report_confirm">
                    <div className="rrcwc_form">
                      <div className="rf_title">
                        Privacy notice - use of personal information
                      </div>
                      {/* {kindCustomerWatcher === "R" ? (
                        <div className="rf_title">
                          Privacy notice - use of personal information <br/>(隐私条款 - 个人信息使用)
                        </div>
                      ) : null} */}
                        <div className="rgw_box">
                          <div className="rgwb_content rgwb_content_en">
                            <div className="rgwbc_des"></div>
                            <div className="rgwbc_des des_left">
                              <span>
                                Personal information collected and the method to
                                collect the information
                              </span>
                              <span>
                                Ethical Management system collects personal
                                information for faith treatment of the report
                                within the minimum required range. The
                                information collected is ‘Name, birthday, e-mail
                                address, telephone (cell phone) number’. If you
                                do not want to provide personal information, you
                                can report via ‘Anonymous’. But anonymous
                                reporting may have some limitation in confirming
                                the receipt of report and handling the case.
                              </span>
                              <span>
                                (个人信息的收集及收集方式
                              </span>
                              <span>
                                伦理管理系统在最低要求范围内收集个人信息用于确认举报准确性。
                                收集的信息有“姓名、生日、E-mail地址、手机号码”。如果您不愿提供个人信息，可通过“匿名”举报。但匿名举报在接收信息和事件处理方面可能有一定的局限性。)
                              </span>
                            </div>
                            <div className="rgwbc_des des_left">
                              <span>
                                Collection of Personal Information and Its
                                Purpose
                              </span>
                              <span>
                                Collection of Personal Information and Its
                                Purpose is for handling the report including
                                additional check for the complaint when
                                necessary, help for confirmation of receipt of
                                report, etc.
                              </span>
                              <span>
                                (个人信息收集及其目的
                              </span>
                              <span>
                                个人信息的收集及其目的是为了处理举报事项，包括在必要时进一步核实举报内容、协助确认接收举报等。)
                              </span>
                            </div>
                            <div className="rgwbc_des des_left">
                              <span>
                                Treatment of Personal Information and Retention
                                Period
                              </span>
                              <span>
                                Personal information collected via Ethical
                                Management system is to be will be destroyed
                                without delay after the purpose of use is
                                achieved, but exceptions apply to cases in need
                                of additional retention.
                              </span>
                              <span>
                                (个人信息处理及保留期限
                              </span>
                              <span>
                                通过伦理管理系统收集的个人信息将在完成使用目的后立即销毁，但需要额外保留的情况除外。)
                              </span>
                            </div>
                          </div>
                        </div>
                      {/* {kindCustomerWatcher === "R" ? (
                        <div className="rgw_box">
                          <div className="rgwb_content rgwb_content_en">
                            <div className="rgwbc_des"></div>
                            <div className="rgwbc_des des_left">
                              <span>
                                Personal information collected and the method to
                                collect the information
                              </span>
                              <span>
                                Ethical Management system collects personal
                                information for faith treatment of the report
                                within the minimum required range. The
                                information collected is ‘Name, birthday, e-mail
                                address, telephone (cell phone) number’. If you
                                do not want to provide personal information, you
                                can report via ‘Anonymous’. But anonymous
                                reporting may have some limitation in confirming
                                the receipt of report and handling the case.
                              </span>
                            </div>
                            <div className="rgwbc_des des_left">
                              <span>
                                Collection of Personal Information and Its
                                Purpose
                              </span>
                              <span>
                                Collection of Personal Information and Its
                                Purpose is for handling the report including
                                additional check for the complaint when
                                necessary, help for confirmation of receipt of
                                report, etc.
                              </span>
                            </div>
                            <div className="rgwbc_des des_left">
                              <span>
                                Treatment of Personal Information and Retention
                                Period
                              </span>
                              <span>
                                Personal information collected via Ethical
                                Management system is to be will be destroyed
                                without delay after the purpose of use is
                                achieved, but exceptions apply to cases in need
                                of additional retention.
                              </span>
                            </div>
                          </div>
                        </div>
                      ) : null} */}
                        <div className="rf_checkbox1_wrap">
                          <div className="rfcw_text">
                            <p>
                            I agree on collection and use of personal information. <br/>(本人同意收集及使用个人信息相关事项。)
                            </p>
                            <div className="rfcw_check">
                              <Controller
                                style={{display:"flex"}}
                                as={
                                  <Radio.Group>
                                    <Radio
                                      className="rf_checkbox1"
                                      value='agree'
                                      style={{alignItems:"center"}}
                                    >
                                      Agree <br/>(同意)
                                    </Radio>
                                    <Radio
                                      className="rf_checkbox1"
                                      value='disagree'
                                      style={{alignItems:"center"}}
                                    >
                                      Disagree <br/>(不同意)
                                    </Radio>
                                  </Radio.Group>
                                }
                                onChange={e => {
                                  const isAgree = e[0].target.value === 'agree';
                                  if(isAgree) {
                                    setrfErrorMsg1(null);
                                  } else {
                                    setrfErrorMsg1("true");
                                    showToastError(
                                      translate("createReport.errcheck1"),
                                      {
                                        autoClose: 5000
                                      }
                                    );
                                  }
                                  return e[0].target.value;
                                }}
                                defaultValue={''}
                                control={control}
                                name="agree1"
                              />
                            </div>
                          </div>
                          <div className="rf_error">
                            {rfErrorMsg1 === "true"
                              ? translate("createReport.rfErrorMsg1")
                              : ""}
                          </div>
                        </div>
                      {/* {kindCustomerWatcher === "R" ? (
                        <div className="rf_checkbox1_wrap">
                          <div className="rfcw_text">
                            <p>
                            I agree on collection and use of personal information.
                            </p>
                            <div className="rfcw_check">
                              <Controller
                                as={
                                  <Radio.Group>
                                    <Radio
                                      className="rf_checkbox1"
                                      value='agree'
                                    >
                                      Agree
                                    </Radio>
                                    <Radio
                                      className="rf_checkbox1"
                                      value='disagree'
                                    >
                                      Disagree
                                    </Radio>
                                  </Radio.Group>
                                }
                                onChange={e => {
                                  const isAgree = e[0].target.value === 'agree';
                                  if(isAgree) {
                                    setrfErrorMsg1(null);
                                  } else {
                                    setrfErrorMsg1("true");
                                    showToastError(
                                      translate("createReport.errcheck1"),
                                      {
                                        autoClose: 5000
                                      }
                                    );
                                  }
                                  return e[0].target.value;
                                }}
                                defaultValue={''}
                                control={control}
                                name="agree1"
                              />
                            </div>
                          </div>
                          <div className="rf_error">
                            {rfErrorMsg1 === "true"
                              ? translate("createReport.rfErrorMsg1")
                              : ""}
                          </div>
                        </div>
                      ) : null} */}

                      <div className="rf_title">
                        "Consent to provide personal information to a third party (同意向第三方提供个人信息)"
                        {/* {kindCustomerWatcher === "R"
                          ? "Consent to provide personal information to a third party"
                          : "Notes on anonymous reporting"} */}
                      </div>
                      <div className="rgw_box">
                      <div className="rgwb_content rgwb_content_en">
                      <div className="rgwbc_des">
                              <span>
                                In order to handle the report, the personal
                                information may be provided to a related
                                company; if you do not want this, you should use
                                Anonymity Report system.
                              </span>
                              <span>
                                (为了处理举报，个人信息可能会提供给关联企业；若不同意，则应选择匿名举报。)
                              </span>
                            </div>
                            <div className="rgwbc_des des_left">
                              <span>
                                Companies to receive the personal information:
                                Hyundai Mobis, Hyundai Steels, Hyundai Wia,
                                Hyundai Transys, Hyundai Glovis, Hyundai
                                Construction, Hyundai Engineering and Hyundai
                                Motor Group's affiliates.
                              </span>
                              <span>
                                (接收个人信息的企业有：现代摩比斯、现代制铁、现代威亚、现代坦迪斯、现代格罗唯视、现代建设、现代工程建设以及现代汽车集团其他分子公司。)
                              </span>
                            </div>
                            <div className="rgwbc_des des_left">
                              <span>
                                Personal information provided: Name, birthday,
                                e-mail address, telephone (cell phone) number
                              </span>
                              <span>(提供的个人信息有：姓名、生日、E-mail地址、手机号码。)</span>
                            </div>
                            <div className="rgwbc_des des_left">
                              <span>
                                Purpose of providing personal information: to
                                handle the report including additional check for
                                the complaint when necessary, help for
                                confirmation of receipt of report, etc.
                              </span>
                              <span>
                                (提供个人信息的目的：处理举报事项，包括在必要时进一步核实举报内容、协助确认接收举报等。)
                              </span>
                            </div>
                            <div className="rgwbc_des des_left">
                              <span>
                                Period of retention and use: the same as
                                Treatment of Personal Information and Retention
                                Period
                              </span>
                              <span>
                                (保留及使用期限：与个人信息的处理及保留期限一致。)
                              </span>
                            </div>
                          </div>
                        {/* {kindCustomerWatcher === "R" ? (
                          <div className="rgwb_content rgwb_content_en">
                            <div className="rgwbc_des">
                              <span>
                                In order to handle the report, the personal
                                information may be provided to a related
                                company; if you do not want this, you should use
                                Anonymity Report system.
                              </span>
                              <span>
                                (为了处理举报，个人信息可能会提供给关联企业；若不同意，则应选择匿名举报。)
                              </span>
                            </div>
                            <div className="rgwbc_des des_left">
                              <span>
                                Companies to receive the personal information:
                                Hyundai Mobis, Hyundai Steels, Hyundai Wia,
                                Hyundai Transys, Hyundai Glovis, Hyundai
                                Construction, Hyundai Engineering and Hyundai
                                Motor Group's affiliates.
                              </span>
                              <span>
                                (接收个人信息的企业有：现代摩比斯、现代制铁、现代威亚、现代坦迪斯、现代格罗唯视、现代建设、现代工程建设以及现代汽车集团其他分子公司。)
                              </span>
                            </div>
                            <div className="rgwbc_des des_left">
                              <span>
                                Personal information provided: Name, birthday,
                                e-mail address, telephone (cell phone) number
                              </span>
                              <span>(提供的个人信息有：姓名、生日、E-mail地址、手机号码。)</span>
                            </div>
                            <div className="rgwbc_des des_left">
                              <span>
                                Purpose of providing personal information: to
                                handle the report including additional check for
                                the complaint when necessary, help for
                                confirmation of receipt of report, etc.
                              </span>
                              <span>
                                (提供个人信息的目的：处理举报事项，包括在必要时进一步核实举报内容、协助确认接收举报等。)
                              </span>
                            </div>
                            <div className="rgwbc_des des_left">
                              <span>
                                Period of retention and use: the same as
                                Treatment of Personal Information and Retention
                                Period
                              </span>
                              <span>
                                (保留及使用期限：与个人信息的处理及保留期限一致。)
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div className="rgwb_content">
                            <div className="rgwbc_des rgwbc_ano">
                              <span className="rgwbcd_ano">
                                Groundless slander and gossip can be closed.
                                <br />
                                Also, depending on the content, we may not be
                                able to inform you of the report results.
                              </span>
                            </div>
                          </div>
                        )} */}
                      </div>
                      <div className="rf_checkbox1_wrap rf_checkbox_wrap_en">
                      <div className="rfcw_text">
                            I agree for provision of the personal Information to
                            the third party. <br/>(本人同意将个人信息提供给第三方。)
                            <div className="rfcw_check">
                              <Controller
                                style={{display:"flex"}}
                                as={
                                  <Radio.Group>
                                    <Radio
                                      className="rf_checkbox1"
                                      value='agree'
                                      style={{alignItems:"center"}}
                                    >
                                      Agree <br/>(同意)
                                    </Radio>
                                    <Radio
                                      className="rf_checkbox1"
                                      value='disagree'
                                      style={{alignItems:"center"}}
                                    >
                                      Disagree <br/>(不同意)
                                    </Radio>
                                  </Radio.Group>
                                }
                                onChange={e => {
                                  const isAgree = e[0].target.value === 'agree';
                                  if(isAgree) {
                                    setrfErrorMsg2(null);
                                  } else {
                                    setrfErrorMsg2("true");
                                    showToastError(
                                      translate("createReport.errcheck1"),
                                      {
                                        autoClose: 5000
                                      }
                                    );
                                  }
                                  return e[0].target.value;
                                }}
                                defaultValue={''}
                                control={control}
                                name="agree2"
                              />
                            </div>
                          </div>
                        </div>
                      {/* {kindCustomerWatcher === "R" ? (
                        <div className="rf_checkbox1_wrap rf_checkbox_wrap_en">
                          <div className="rfcw_text">
                            I agree for provision of the personal Information to
                            the third party. <br/>(本人同意将个人信息提供给第三方。)
                            <div className="rfcw_check">
                              <Controller
                                style={{display:"flex"}}
                                as={
                                  <Radio.Group>
                                    <Radio
                                      className="rf_checkbox1"
                                      value='agree'
                                      style={{alignItems:"center"}}
                                    >
                                      Agree <br/>(同意)
                                    </Radio>
                                    <Radio
                                      className="rf_checkbox1"
                                      value='disagree'
                                      style={{alignItems:"center"}}
                                    >
                                      Disagree <br/>(不同意)
                                    </Radio>
                                  </Radio.Group>
                                }
                                onChange={e => {
                                  const isAgree = e[0].target.value === 'agree';
                                  if(isAgree) {
                                    setrfErrorMsg2(null);
                                  } else {
                                    setrfErrorMsg2("true");
                                    showToastError(
                                      translate("createReport.errcheck1"),
                                      {
                                        autoClose: 5000
                                      }
                                    );
                                  }
                                  return e[0].target.value;
                                }}
                                defaultValue={''}
                                control={control}
                                name="agree2"
                              />
                            </div>
                          </div>
                        </div>
                      ) : null} */}
                    </div>
                  </div>
                )}
              </form>
              <button
                style={{ display: "none" }}
                onClick={() => {
                  clearError([
                    "mobilePhone",
                    "email",
                    "titleReport",
                    "content",
                    "name"
                  ]);
                }}
              >
                TEST
              </button>
              <div
                className={`rf_checkboxAll_wrap ${
                  null
                  // kindCustomerWatcher === "R" ? null : "rf_checkboxAll_en"
                }`}
              >
                <Checkbox
                  className="rf_checkboxAll"
                  checked={
                    getValues('agree1') === 'agree' && getValues('agree2') === 'agree' ? true : false
                  }
                  name="checkBox3"
                  onChange={e => {
                    console.log('getValues', getValues());
                    if (e.target.checked === true) {
                      setValue('agree1', 'agree');
                      setValue('agree2', 'agree');
                      setrfErrorMsg1(null);
                      setrfErrorMsg2(null);
                    } else {
                      setValue('agree1', 'disagree');
                      setValue('agree2', 'disagree');
                      setrfErrorMsg1("true");
                      setrfErrorMsg2("true");
                      showToastError(translate("createReport.errcheck"), {
                        autoClose: 5000
                      });
                    }
                  }}
                  onFocus={() => {
                    let e = document.getElementsByClassName("rf_checkboxAll");
                    e[0].children[0].style.border = "1px solid";
                  }}
                  onClick={() => {
                    let e = document.getElementsByClassName("rf_checkboxAll");
                    e[0].children[0].removeAttribute("style");
                  }}
                  onBlur={() => {
                    let e = document.getElementsByClassName("rf_checkboxAll");
                    e[0].children[0].removeAttribute("style");
                  }}
                ></Checkbox>
                <span className="rfcw_text" style={{ backgroundColor: "white" }}>
                  {translate("createReport.checkboxAll")}
                </span>
              </div>
              <div className="confirm_btn">
                <a
                  href="/report/guideline"
                  title={translate("TooltipReport.Realname.btnCancel")}
                >
                  {translate("reportRegistration.cancel")}
                </a>
                <button
                  onClick={handleSubmit(onSubmit)}
                  disabled={errorDOB ? true : false}
                  title={translate("TooltipReport.Realname.btnConfirm")}
                  id={"submitForm"}
                >
                  {translate("reportRegistration.confirm")}
                </button>
              </div>
            </div>
          </div>
          {code === "kr" ? (
            <div className="rrcw_footer">
              <div className="rrcwf_wrap">
                <div className="rrcwf_block1">
                  <img src="/Images/icwarning.svg" alt="" />
                  <span>{translate("reportRegistration.warning")}</span>
                </div>
                <div className="rrcwf_block2">
                  <span> {translate("reportRegistration.warning_2")}</span>
                  <div className="item-block2">
                    <a href="https://www.hyundai.com/kr/ko/ask">
                      <div className="text-block2" tabIndex="-1">
                        {translate("reportRegistration.hyundai")}
                      </div>
                    </a>
                    <img
                      src="/Images/kia_logo.svg"
                      alt={translate("TooltipHome.iconHuyndai")}
                      title={translate("TooltipHome.iconHuyndai")}
                    />
                  </div>
                  <div className="item-block2">
                    <div className="text-block2">
                      <a href="https://www.kia.com/kr/customer-service/default.html">
                        <span tabIndex="-1">
                          {translate("reportRegistration.kia")}
                        </span>
                      </a>
                    </div>
                    <img
                      src="/Images/kia_black.svg"
                      alt={translate("TooltipHome.iconKia")}
                      title={translate("TooltipHome.iconKia")}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>

      {/* Popup modal  */}
      <Modal
        className="modalStart"
        visible={modalStart}
        footer={null}
        maskClosable={false}
        width="792px"
        onCancel={e => {
          e.stopPropagation();
          setModalStart(false);
          window.scrollTo({ top: 0 });
        }}
        closeIcon={
          <img
            src="/Images/icclose36px.svg"
            alt={translate("webAccessibility.closeButtonReport")}
          />
        }
      >
        {contentPopup ? (
          <div>
            <div className="modalStart_title">{contentPopup.title}</div>
            <div
              className="modalStart_content"
              dangerouslySetInnerHTML={{
                __html: contentPopup.content
              }}
            ></div>
            <button
              className="button_close_modal"
              onClick={e => {
                e.stopPropagation();
                setModalStart(false);
                window.scrollTo({ top: 0 });
              }}
            >
              {" "}
              {code === "kr" ? "닫기" : "Close"}
            </button>
          </div>
        ) : (
          <div>
            <div className="modalStart_title">
              {" "}
              {translate("reportRegistration.titleHead2")}
            </div>
            {code === "kr" ? (
              <div className="modalStart_content">
                <div className="msc_item">
                  <div className="msci_left msci_item">
                    {/* <span className="title">• 이름:</span> */}
                    <span className="title">이름 :</span>
                    <span className="content">나현대(익명 선택 가능)</span>
                  </div>
                  <div className="msci_right msci_item">
                    {/* <span className="title">• 생년월일 :</span> */}
                    <span className="title">생년월일 :</span>
                    <span className="content">1967-12-29</span>
                  </div>
                </div>
                <div className="msc_item">
                  <div className="msci_item msci_mobile_email">
                    {/* <div className="title">• E-Mail :</div> */}
                    <span className="title" lang="en">
                      E-Mail :
                    </span>
                    <div className="content">
                      <span lang="en">OOO@hyundai.com</span>(연락가능 메일)
                    </div>
                  </div>
                </div>
                <div className="msc_item">
                  <div className="msci_left msci_item">
                    {/* <span className="title">• 전화번호 :</span> */}
                    <span className="title">전화번호 :</span>
                    <span className="content">02-1234-1234</span>
                  </div>
                  <div className="msci_right msci_item">
                    {/* <span className="title">• 핸드폰번호:</span> */}
                    <span className="title">핸드폰번호 :</span>
                    <span className="content">010-1234-1234</span>
                  </div>
                </div>
                <div className="msc_item">
                  <div className="msci_item msci_mobile_2">
                    {/* <div className='title'>• 제목:</div> */}
                    <div className="title">제목 :</div>
                    <div className="content">
                      현대자동차 OO 팀 나기아 부장의 OO 업체로 부터 금품 수수
                      제보
                    </div>
                  </div>
                </div>
                <div className="msc_item bb_0">
                  <div className="msci_item msci_content">
                    {/* <div className="title">
                      • 작성요령(최대한 자세히 기록 바랍니다.)
                    </div> */}
                    <div className="title">
                      작성요령(최대한 자세히 기록 바랍니다.)
                    </div>
                    <div className="item">
                      {/* <span className="left">①</span> */}
                      {/* <span className="left">1.</span> */}
                      <div>
                        1. 제보하고자 하는 내용 <br /> : 내가 했거나 당한 일임,
                        내가 직접 보거나 들음, 직장동료/업체 등에게 들음,
                        소문으로 알게 된 내용임 등
                      </div>
                    </div>
                    <div className="item">
                      {/* <span className="left">②</span> */}
                      {/* <span className="left">2.</span> */}
                      <div>
                        2. 제공자(업체) <br />: OO 업체 대표 홍길동, 영업과장
                        나일등
                      </div>
                    </div>
                    <div className="item">
                      {/* <span className="left">③</span> */}
                      {/* <span className="left">3.</span> */}
                      <div>
                        3. 제보 대상 <br />: OOO 사업부 OOO팀 나기아 차장,
                        너현대 과장
                      </div>
                    </div>
                    <div className="item">
                      {/* <span className="left">④</span> */}
                      {/* <span className="left">4.</span> */}
                      <div>
                        4. 부정비리 상세내용(시기, 장소, 규모)
                        <br /> : &apos;17년 12월 경 나기아 차장에게 OO업체 OOO
                        대표가 현금 OO만원을
                        <br />흰 봉투에 넣어 OO횟집에서...(나기아 차장 아내의
                        통장으로...)
                        <br /> &apos;17년 1월 1일 너현대 과장에게 OO업체
                        OO과장이 역삼동 OO고급주점에서 접대...
                      </div>
                    </div>
                    <div className="item">
                      {/* <span className="left">⑤</span> */}
                      {/* <span className="left">5.</span> */}
                      <div>
                        5. 접대/향응 제공 목적
                        <br />: OO 프로젝트 수주 특혜 목적/입찰정보 제공 요청 등
                      </div>
                    </div>
                    <div className="item">
                      {/* <span className="left_kr">⑥</span> */}
                      {/* <span className="left_kr">6.</span> */}
                      <div>
                        {/* 기타내용: 해당 내용을 알것으로 예상되는 사람은 OOO이고,
                        연락처는 010-1234-1234입니다.
                      <br /> 사실 확인을 위한 방법은 OOO입니다. 등의 추가내용
                      기입 */}
                        6. 기타 내용
                        <br />: 해당 내용을 알 것으로 예상되는 사람은 OOO이고,
                        연락처는 010-1234-1234입니다.
                        <br />
                        사실 확인을 위한 방법은 OOO입니다. 등의 추가 내용 기입
                      </div>
                    </div>
                  </div>
                </div>
                <div className="msc_item msc_item_last">
                  <span>※ </span>
                  <div>
                    제보내용(실명,특정업체명 등)이 구체적이지 않을 경우 조사가
                    지연되거나 보류될 수 있으니 최대한 자세히 기록바라며,{" "}
                    <span>
                      근거없는 비방 및 험담 등은 종결 처리 될 수 있음을 양지
                      바랍니다.
                    </span>
                  </div>
                  <div></div>
                </div>
              </div>
            ) : (
              <div className="modalStart_content">
                <div className="msc_item">
                  <div className="msci_left msci_item">
                    {/* <span className="title">&bull; Name:</span> */}
                    <span className="title">Name :</span>
                    <span className="content"> John Smith </span>
                  </div>

                  <div className="msci_right msci_item">
                    {/* <span className="title">&bull; Date of birth :</span> */}
                    <span className="title">Date of birth :</span>
                    <span className="content">29-Dec-1967</span>
                  </div>
                </div>

                <div className="msc_item">
                  <div className="msci_item msci_mobile_email">
                    {/* <div className="title">&bull; E-Mail :</div> */}
                    <div className="title">E-Mail :</div>
                    <div className="content"> □□□＠gmail.com </div>
                  </div>
                </div>

                <div className="msc_item">
                  <div className="msci_left msci_item">
                    {/* <span className="title">&bull; Phone number :</span> */}
                    <span className="title">Phone number :</span>
                    <span className="content">82(Nation code)212341234</span>
                  </div>

                  <div className="msci_right msci_item">
                    {/* <span className="title">&bull; Mobile Phone:</span> */}
                    <span className="title">Mobile Phone :</span>
                    <span className="content">821012341234</span>
                  </div>
                </div>

                <div className="msc_item">
                  <div className="msci_item msci_mobile_2">
                    {/* <div className="title title_en">&bull; Report title:</div> */}
                    <div className="title title_en">Report title :</div>
                    <div className="content">
                      Report on bribery from △ company to Hyundai Motor □ Team
                    </div>
                  </div>
                </div>

                <div className="msc_item bb_0">
                  <div className="msci_item msci_content">
                    {/* <div className="title">
                      &bull; Write instructions (please record as much detail as
                      possible)
                    </div> */}
                    <div className="title">
                      Write instructions (please record as much detail as
                      possible)
                    </div>
                    <div className="item">
                      {/* <span className="left">①</span> */}
                      <span className="left">1.</span>
                      <div>
                        What I would like to report:
                        <br />
                        - What I&#39;ve done or done, I&#39;ve seen or heard in
                        person, i hear it from a co-worker/business partner,
                        <br />- What I&#39;ve learned from rumors, etc.
                      </div>
                    </div>

                    <div className="item">
                      {/* <span className="left">②</span> */}
                      <span className="left">2.</span>
                      <div>
                        Suppliers: OO Company Ceo Hong Gil-dong, Sales Manager
                        Nile, etc.
                      </div>
                    </div>

                    <div className="item">
                      {/* <span className="left">③</span> */}
                      <span className="left">3.</span>
                      <div>Report: OOO Deputy Director, OOO Team</div>
                    </div>

                    <div className="item">
                      {/* <span className="left">④</span> */}
                      <span className="left">4.</span>
                      <div>
                        Details of the irregularities (timing, place, scale):
                        <br />
                        - &#39;Around December 17, ooo representative of OO
                        company OOO put the cash OO won in a white bag... (As a
                        passbook of Nagia Deputy Director Wipe...)
                        <br />- &lsquo;January 17, 17, OOOO Company (Executive director ooo) provides entertain to Kimㅇㅇ(Hyundai) at ㅇㅇ karaoke (Kangnam station).&hellip;
                      </div>
                    </div>

                    <div className="item">
                      {/* <span className="left">⑤</span> */}
                      <span className="left">5.</span>
                      <div>
                        The purpose of entertainment/entertainment is: oo
                        project order preferential purpose / request to provide
                        bid information, etc.
                      </div>
                    </div>

                    <div className="item">
                      {/* <span className="left">⑥</span> */}
                      <span className="left">6.</span>
                      <div>
                        Other information: Ooo is the person who is expected to
                        know the information, and contact sit at 010-1234-1234.
                        Ooo is the way to verify the facts. Fill out additional
                        details such as&hellip;.
                      </div>
                    </div>
                  </div>
                </div>

                <div className="msc_item msc_item_last">
                  <span>※ </span>

                  <div>
                    If the report (real name, specific company name, etc.) is
                    not specific, the investigation may be delayed or suspended,
                    so please note that the unfounded slander and gossip may be
                    closed.
                  </div>

                  <div>&nbsp;</div>
                </div>
              </div>
            )}

            <button
              className="button_close_modal"
              onClick={e => {
                e.stopPropagation();
                setModalStart(false);
                window.scrollTo({ top: 0 });
              }}
            >
              {" "}
              {code === "kr" ? "닫기" : "Close"}
            </button>
          </div>
        )}
      </Modal>
      <Flex className="btn_totop">
        <a
          href="javascript: void(0)"
          tabIndex="0"
          id="backtotoprealname"
          onClick={scrollTop}
          onKeyPress={handleKeyPress}
        >
          <img
            src="/Images/icarrowupward48px.svg"
            alt={translate("TooltipHome.btnToTop")}
            title={translate("TooltipHome.btnToTop")}
          />
        </a>
      </Flex>
      <button
        className="clear-error-input"
        style={{ display: "none" }}
        id="clear-error-input"
        onClick={() => {
          if (
            errors.mobilePhone &&
            errors.mobilePhone.message == translate("report.mobiphoneRequired")
          ) {
            clearError(["mobilePhone"]);
          }
          if (
            errors.content &&
            errors.content.message == translate("report.contentRequired")
          ) {
            clearError(["content"]);
          }
        }}
      >
        clear
      </button>
    </div>
  );
};

export default withLocalize(RealName);
